import { Component, OnInit, Input } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../store';
import { ADD_TODO, ENVSTATUS, COOKIE_SET } from '../../actions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-block-tools-popup',
  templateUrl: './block-tools-popup.component.html',
  styleUrls: ['./block-tools-popup.component.scss']
})
export class BlockToolsPopupComponent implements OnInit {
  tools: any;
  items: string;
  ArrayCheckbox: any;
  lastAction: any;
  objTab: any;
  x: any;
  nItems: number;
  @Input() data;

  constructor(
    public doCall: ServiceDoCallService,
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<BlockToolsPopupComponent>,
    private WalletManager: ServiceWalletManagerService,
    private ngRedux: NgRedux<IAppState>
    ) { }

  onSearchChange(searchValue:string,strlength) {
      let text = searchValue.toLowerCase();
      let params =  {"s": text};
      if(text.length> 2){
        this.doCall.Post(environment.EP_Search, params).subscribe(result => {
          this.tools = result;
          if (this.tools != null) {
            //this.items = this.tools.filter(x => x.label.toLowerCase().includes(text));
            this.items = this.tools;
          }
        });
      }
    }

  countItems(){
    //calcolo il numero di elementi
    this.nItems = this.objTab[this.data.index].items.length;
    this.objTab[this.data.index].nItems = this.nItems;
  }

  TakeChecked(Array) {
    let ArrayChecked: any = [];
    let storage: any = this.WalletManager.GetWallet();
    this.objTab = storage.ptfs;
    Array.forEach((item) => {
      const isPresent = this.objTab[this.data.index].items != '' ? this.objTab[this.data.index].items.filter(e => e.slug === item.slug).length > 0 : false;
      if (item.checked === true && !isPresent) {
        ArrayChecked.push(item);
      }
    });

    if (this.objTab[this.data.index].items === '') {
      this.objTab[this.data.index].items = ArrayChecked;
    } else {
      ArrayChecked.forEach((tool) => {
        this.objTab[this.data.index].items.push(tool);
      });
    }
    this.countItems();
    
    storage.ptfs = this.objTab;
    this.WalletManager.SetWallet(storage); 

    this.ngRedux.dispatch({
      type: COOKIE_SET,
      cookie: {
        'WalletNAme': storage
      }
    });

  }

  onChange(event, index, result) {
    result.checked = !result.checked;
    this.lastAction = 'index: ' + index + ', label: ' + result.label + ', checked: ' + result.checked + ', slug: ' + result.slug;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit() {
    
  }

}
