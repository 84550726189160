import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceAuthService } from 'src/app/services/service-auth.service';

@Component({
  selector: 'modules/module-nav',
  templateUrl: './module-nav.component.html',
  styleUrls: ['./module-nav.component.scss']
})
export class ModuleNavComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
    
  constructor(
    private breakpointObserver: BreakpointObserver,
    private Login: ServiceAuthService
    ) {}

  logout(){
    this.Login.DoLogout();
  }
  
}




