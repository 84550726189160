import { Component, OnInit, Input, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { jqxChartComponent } from '../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource, MatSort } from '@angular/material';
import { ServiceExcelExportService } from '../../services/service-excel-export.service';


@Component({
  selector: 'app-chart-spider',
  templateUrl: './chart-spider.component.html',
  styleUrls: ['./chart-spider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChartSpiderComponent implements OnInit {
  @Input() endpoint;
  @Input() indexWallet;
  @Input() label;
  @Input() id;
  data: any;
  tab_data: any;
  tab_rows: any;
  tab_columns: any;
  option: any;
  dataSource:any;
  displayedColumns:any;
  xAxis : any = {
    dataField: 'label',
    gridLines: { visible: true},
    labels: {
      formatFunction : function(str){
        let newString = "";
        let l=35;
        while(str.length > l){
          var pos = str.substring(0, l).lastIndexOf(' ');
          pos = pos <= 0 ? l : pos;
          newString = newString + "<br>" + (str.substring(0, pos));
          var i = str.indexOf(' ', pos)+1;
          if(i < pos || i > pos+l)
              i = pos;
          str = str.substring(i);
        }
        newString = newString + "<br>" + str;
        return newString;
      }
    }
  };
  valueAxis : any = {
    gridLines: { visible: true, step: 5, unitInterval: 5},
    minValue:-1,
    maxValue:1.1,
    labels: {step:4}
  };
  seriesGroups: any[] =
  [
    {
      type: 'spline',
      spider:true,
      height:200,
      showLabels: true,
      series:
      [{
        dataField: 'value',
        color:"#02BAC5",
        formatSettings: {
          decimalPlaces: 2
        }
      }]
    }
  ];
  @ViewChild('myChart')myChart: jqxChartComponent;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private WalletManager: ServiceWalletManagerService,
    private doCall: ServiceDoCallService,
    private excelExport: ServiceExcelExportService
  ) {
    this.tab_data = {};
   }

  getData(){
    let storage:any = this.WalletManager.GetWallet(); 
    let url = this.endpoint;
    let id = this.id;
    if(!this.id){
      id = storage.ptfs[this.indexWallet].id;
    }
    this.option =  {"id": id};
    this.doCall.Post(url, this.option).subscribe(res => {
      let mydata = {};
      let Arr = [];
      for (let key in res["chart"]) {
        mydata = {
          label : key,
          value : res["chart"][key]
        }
        Arr.push(mydata);
        this.data = Arr;
      }
      if(this.label!="Correlations"){
        this.valueAxis.minValue = undefined;
        this.valueAxis.maxValue = undefined;
        this.valueAxis.labels= {
          formatFunction : function(str){
            return "";
          }
        }
        this.seriesGroups[0].polar = true;
        this.seriesGroups[0].spider = false;
      }
      
      let mat_tab = [];
      if( res["tab"]!=undefined){
        this.tab_data = res["tab"];
        for(let row in this.tab_data){
          let data = this.tab_data[row];
          mat_tab.push(data);
        }
        const ELEMENT_DATA = mat_tab;
        this.displayedColumns = res["tab_columns"];
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.dataSource.sort = this.sort;
      }
      let timer = setTimeout(() => {
        this.myChart.update();
      }, 1);
    });
  }

  ngOnInit() {
    if(this.endpoint){
      this.getData();
    }
    let timer = setTimeout(() => {
      this.myChart.update();
    }, 1);
  }

  pngExportOnClick() {
    function getExportServer() {
      return 'http://www.jquerygrid.net/export_server/export.php';
    }
    this.myChart.saveAsPNG("spiderchart.png",getExportServer());
   };

   excelExportOnClick() {
    this.excelExport.exportAsExcelFile(this.data,"data");
   };

}
