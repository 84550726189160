import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-module-select-currency',
  templateUrl: './module-select-currency.component.html',
  styleUrls: ['./module-select-currency.component.scss']
})
export class ModuleSelectCurrencyComponent implements OnInit {
  selected = 'option2';
  @Input() tab;
  constructor() { }

  ngOnInit() {
  }

}
