import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ModuleTabWalletComponent } from '../../modules/module-tab-wallet/module-tab-wallet.component';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { environment } from 'src/environments/environment';
import { ServiceAuthService } from '../../services/service-auth.service';
import { BlockViewitemPopupComponent } from '../block-viewitem-popup/block-viewitem-popup.component';
import { MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'app-block-show-tools',
  templateUrl: './block-show-tools.component.html',
  styleUrls: ['./block-show-tools.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlockShowToolsComponent implements OnInit {
  @Input() obj;
  @Input() tab;
  @Input() disabled;  
  objTab: any;
  checked : boolean;
  selectAll = false;
  total: any;
  nItems: number;
  names:any;
  frequences:any;
  currencies:any;
  slugsCurrencies:any;
  inceptions:any;
  lastupdates:any;
  proxyinceptions:any;
  morncats:any;
  types:any;
  peers:any;
  isinsortickers:any;
  infocurrencies:any;
  availableStart:any;
  availableEnd:any;
  availableYears:any;
  itemsCount:any;
  freqsLabels:any;
  constructor(
    private WalletManager: ServiceWalletManagerService,
    private parent: ModuleTabWalletComponent,
    public auth: ServiceAuthService,
    public dialog: MatDialog,
    public doCall: ServiceDoCallService
  ){
    this.names = {};
    this.freqsLabels = {"B":"daily","W-FRI":"weekly","SM":"bi-weekly","BM":"monthly","BQ":"quarterly","BA":"yearly"};
    this.frequences = {};
    this.inceptions = {};
    this.lastupdates = {};
    this.proxyinceptions = {};
    this.morncats = {};
    this.types = {};
    this.peers = {};
    this.isinsortickers = {};
    this.infocurrencies = {};
  }

  //Check if there are checked checbox
  onChange(Array) {
    this.checked = false;
    Array.forEach((item)  => {
      if(item.checked === true){
        this.checked = true;
      }
      this.parent.ButtonVisible = this.checked;
    });
    this.GetItemsTimeFrame();
    this.GetTimeFrames();
    this.GetItemsInfo();
    this.CountItems();
  }
  

  Total(){
    //calcolo la somma dei valori e aggiorno totale
    this.total = 0;
    if(this.obj.settings.weightsOptions.weightsMode == 'ManualWeights' || this.obj.settings.weightsOptions.weightsMode == 'ManualRisks'){
      this.obj.items.forEach(element => {
        if(element.weight){
          this.total = this.total + parseFloat(element.weight);
        }
      });
    }
    else if(this.obj.settings.weightsOptions.weightsMode == 'EqualWeights' || this.obj.settings.weightsOptions.weightsMode == 'EqualRisks'){
      this.obj.items.forEach(element => {
        if(element.equalWeight){
          this.total = this.total + parseFloat(element.equalWeight);
        }
      });
    }
    else
      this.total = 0;
    
    this.total = this.total.toFixed(2);
  }

  onWeightChange(weightValue : string, index ) {  
    let storage: any = this.WalletManager.GetWallet();
    storage.ptfs[this.tab].items[index].weight = weightValue;
    this.Total();

    this.WalletManager.SetWallet(storage);
  }

  onMinWeightChange(minWeightValue : string, index ) {  
    let storage: any = this.WalletManager.GetWallet();
    storage.ptfs[this.tab].items[index].minWeight = minWeightValue;

    this.WalletManager.SetWallet(storage);
  }

  onMaxWeightChange(maxWeightValue : string, index ) {  
    let storage: any = this.WalletManager.GetWallet();
    storage.ptfs[this.tab].items[index].maxWeight = maxWeightValue;

    this.WalletManager.SetWallet(storage);
  }

  onCurrChange(currValue : string, index ) {
    let storage: any = this.WalletManager.GetWallet();
    storage.ptfs[this.tab].items[index].curr = currValue;
    this.obj.items[index].curr =  currValue;
    this.WalletManager.SetWallet(storage);
  }

  GetOriginalCurr(){
    let storage: any = this.WalletManager.GetWallet();
    
    let slugs = [];
    this.obj.items.forEach((element) => {
      slugs.push(element.slug)
    });

    this.doCall.Post(environment.EP_SlugsCurrencies,{"slugs":slugs}).subscribe(result => {
      this.slugsCurrencies = result;
      let j = 0;
      this.obj.items.forEach((element) => {
          storage.ptfs[this.tab].items[j].curr = this.slugsCurrencies[j];
          this.obj.items[j].curr = this.slugsCurrencies[j];
        j = j+1;
      });
      this.WalletManager.SetWallet(storage);
    });
  }

  GetItemsTimeFrame(){
    this.obj.items.forEach((element) => {
      this.doCall.Post(environment.EP_Inceptions,{"slugs":[element.slug]}).subscribe(result => {
        this.inceptions[element.slug] = result[0][0];
        this.lastupdates[element.slug] = result[0][1];
        this.proxyinceptions[element.slug] = result[0][2];
      });
    });
  }

  GetItemsInfo(){
    this.obj.items.forEach((element) => {
      this.doCall.Post(environment.EP_ItemAnagraphics,{"slugs":[element.slug]}).subscribe(result => {
        this.types[element.slug] = result[0][0];
        this.morncats[element.slug] = result[0][1];
        this.peers[element.slug] = result[0][2];
        this.isinsortickers[element.slug] = result[0][3];
        this.infocurrencies[element.slug] = result[0][4];
        this.names[element.slug] = result[0][5];
        this.frequences[element.slug] = this.freqsLabels[result[0][6]];
      });
    });
  }

  GetTimeFrames(){
    let storage : any = this.WalletManager.GetWallet();
    let slugs = [];
    storage.ptfs[this.tab].items.forEach((element) => {
      slugs.push(element.slug);
    });
    this.doCall.Post(environment.EP_TimeFrame,{
      "slugs":slugs,"weightsOptions":storage.ptfs[this.tab].settings.weightsOptions,"useProxy":storage.ptfs[this.tab].options.useProxy}).subscribe(result => {
      this.availableStart = result[0];
      this.availableEnd = result[1];
      let start = new Date(this.availableStart);
      let end = new Date(this.availableEnd);
      var diff = Math.abs(start.getTime() - end.getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      this.availableYears = Math.round(diffDays/365*10)/10
    });
  }

  CountItems(){
    this.itemsCount =  this.obj.items.length;
  }

  SetAllEur(){
    let storage: any = this.WalletManager.GetWallet();
    
    let slugs = [];
    let k = 0;
    this.obj.items.forEach((element) => {
      this.obj.items[k].curr = "EUR";
      storage.ptfs[this.tab].items[k].curr = "EUR";
      k = k+1;
    });
      this.WalletManager.SetWallet(storage);
  }

  SetAllEurHdg(){
    let storage: any = this.WalletManager.GetWallet();
    
    let slugs = [];
    let k = 0;
    this.obj.items.forEach((element) => {
      this.obj.items[k].curr = "EURhdg";
      storage.ptfs[this.tab].items[k].curr = "EURhdg";
      k = k+1;
    });
      this.WalletManager.SetWallet(storage);
  }

  SetAllUsd(){
    let storage: any = this.WalletManager.GetWallet();
    
    let slugs = [];
    let t = 0;
    this.obj.items.forEach((element) => {
      this.obj.items[t].curr = "USD";
      storage.ptfs[this.tab].items[t].curr = "USD";
      t = t+1;
    });
      this.WalletManager.SetWallet(storage);
  }

  openFundReport(slug): void {
    let dialogRef = this.dialog.open(BlockViewitemPopupComponent, {
      width: '1700px',
      height: '800px',
      maxWidth: '98%',
      minWidth: '95%',
      maxHeight: '98%',
      minHeight: '95%'
    });
    let instance = dialogRef.componentInstance;
    instance.slug = slug;
    instance.name =this.names[slug];
    instance.code =this.isinsortickers[slug];
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnInit() {
    this.auth.RedirectToLogin();
    if(this.obj.items != ''){
      this.obj.items.map((item)=>{
        item.checked=false;
      });
    }
    let storage: any = this.WalletManager.GetWallet();
    let index = 0;
    if(this.obj.settings.weightsOptions.weightsMode == 'ManualWeights' || this.obj.settings.weightsOptions.weightsMode == 'ManualRisks'){
      this.obj.items.forEach((element) => {
        storage.ptfs[this.tab].items[index].weight = element.weight;
        index = index+1;
      });
      this.Total();
    }
    else if(this.obj.settings.weightsOptions.weightsMode == 'EqualWeights' || this.obj.settings.weightsOptions.weightsMode == 'EqualRisks'){
      this.obj.items.forEach((element) => {
        element.equalWeight = Math.round(100/storage.ptfs[this.tab].nItems*100)/100;
        storage.ptfs[this.tab].items[index].equalWeight = element.equalWeight;
        index = index+1;
      });
      this.Total();
    }
    else if(this.obj.settings.weightsOptions.weightsMode == 'OptimWeights'){
      this.obj.items.forEach((element) => {
        if(this.obj.settings.weightsOptions.bounds == "bounded"){
          if(element.maxWeight == undefined)
            element.maxWeight = 100;
          if(element.minWeight == undefined)
            element.minWeight = 0;
        }
        else if(this.obj.settings.weightsOptions.bound!= undefined && this.obj.settings.weightsOptions.bounds[index] != undefined){
          element.maxWeight = this.obj.settings.weightsOptions.bounds[index][1]
          element.minWeight = this.obj.settings.weightsOptions.bounds[index][0]
        }
        else{
          if(element.maxWeight == undefined)
            element.maxWeight = 100;
          if(element.minWeight == undefined)
            element.minWeight = 0;
        }
         
        storage.ptfs[this.tab].items[index].maxWeight = element.maxWeight;
        storage.ptfs[this.tab].items[index].minWeight = element.minWeight;
        index = index+1;
      });
    }

    this.WalletManager.SetWallet(storage);

    this.doCall.Get(environment.EP_Currencies,"").subscribe(result => {
      this.currencies = result;
    });

    this.GetItemsTimeFrame();
    this.GetTimeFrames();
    this.GetItemsInfo();
    this.CountItems();
    
  }
}
