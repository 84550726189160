// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let Ip = 'http://80.211.61.22';
let Port = '8000';
let ServiceUrl = Ip + ":" + Port;
let AnthiliaSite = "http://podessys.anthilia.it/ranking/";
export const environment = {
    production: true,
	EP_CumulativeRanking: AnthiliaSite + 'ranking.php',
	EP_YbyRanking: AnthiliaSite + 'rankingyby.php',
    EP_Generic: ServiceUrl + "/",
    EP_Search : ServiceUrl + '/list/search',
    EP_SavePtf : ServiceUrl + '/save/ptf',
	EP_DeletePtf : ServiceUrl + '/save/clear_ptf',
	EP_DeleteMarked : ServiceUrl + '/save/clear_marked',
	EP_DeleteCart : ServiceUrl + '/save/clear_cart',
    EP_CachePtf : ServiceUrl + '/cache/ptf',
    EP_Thorizon : ServiceUrl +  '/list/opts/thorizon',
    EP_Frequence : ServiceUrl +  '/list/opts/frequence',
	EP_Currencies : ServiceUrl +  '/list/curncy',
	EP_SlugsCurrencies : ServiceUrl +  '/list/slugscurncy',
    EP_ChartNav : ServiceUrl +  '/chart/nav',
    EP_TabRet : ServiceUrl + '/tab/ret',
    EP_DistRet : ServiceUrl + '/chart/distret',
	EP_Drawdowns : ServiceUrl + '/chart/drawdowns',
    EP_RollStat: ServiceUrl + '/chart/rollstat',
    EP_Rebalance: ServiceUrl + '/list/opts/rebalance',
    EP_Weights: ServiceUrl + '/list/opts/weights',
	EP_Optimizations: ServiceUrl + '/list/opts/optimizations',
    EP_TabWeights: ServiceUrl + '/tab/weights',
    EP_ChartWeights: ServiceUrl + '/chart/weights',
    EP_WeightEvolution: ServiceUrl + '/chart/weightevolution',
    EP_Login: ServiceUrl +  '/login',
    EP_MyPtf: ServiceUrl + '/list/myptf',
	EP_MyCart: ServiceUrl + '/list/cart',
	EP_MyMarked: ServiceUrl + '/list/marked',
    EP_MyPtfParams: ServiceUrl + '/list/myptfparams',
    EP_PerfContrib: ServiceUrl + '/chart/perfcontrib',
	EP_RiskContrib: ServiceUrl + '/chart/riskcontrib',
	EP_Betas: ServiceUrl + '/chart/radarbetamul',
	EP_SingleBetas: ServiceUrl + '/chart/radarbetasingle',
	EP_Correlations: ServiceUrl + '/chart/radarcorr',
	EP_YbyCorrelations: ServiceUrl + '/tab/ybycorr',
	EP_InternalCorrelations: ServiceUrl + '/tab/internalcorr',
	EP_Diversification: ServiceUrl + '/chart/diversification',
	EP_YbyBetas: ServiceUrl + '/tab/ybybeta',
	EP_YbySingleBetas: ServiceUrl + '/tab/ybysinglebeta',
	EP_TabStats: ServiceUrl + '/tab/stats',
	EP_Ters: ServiceUrl + '/chart/ters',
	EP_MgmtFees: ServiceUrl + '/chart/mgmtfees',
	EP_PortfolioCosts: ServiceUrl + '/tab/ptfcosts',
	EP_Costs: ServiceUrl + '/tab/costs',
	EP_TabCompStats: ServiceUrl + '/tab/compstats',
	EP_Inceptions: ServiceUrl + '/list/inceptions',
	EP_TimeFrame: ServiceUrl + '/list/timeframeavailable',
	EP_Tails: ServiceUrl + '/list/opts/tails',
	EP_ChartCompNav: ServiceUrl + '/chart/compnav',
	EP_WeightsSummary: ServiceUrl + '/tab/weightssummary',
	EP_Categories: ServiceUrl + '/categories',
	EP_Classifications : ServiceUrl + '/classifications',
	EP_ItemAnagraphics: ServiceUrl + '/list/anagraphicinfos',
	EP_PeerChartNav: ServiceUrl + '/chart/peernav',
	EP_Rankings: ServiceUrl + '/ranking_list',
	EP_Indicators: ServiceUrl + '/list/indicators',
	EP_CacheSimPtf: ServiceUrl + '/cache/simptf',
	EP_ChartSimNav: ServiceUrl +  '/chart/simnav',
	EP_ItemVol: ServiceUrl +  '/list/itemvol',
	EP_TabSimPctlStats: ServiceUrl +  '/tab/simpctlstats',
	EP_ChartSimBounds: ServiceUrl +  '/chart/simbounds',
	EP_TabSimStats: ServiceUrl +  '/tab/simstats',
	EP_DistSimStats : ServiceUrl + '/chart/simstatsdist',
	EP_Scenarios : ServiceUrl + '/list/scenarios',
	EP_PortfolioItems : ServiceUrl + '/chart/savedportfolioitems',
	EP_ChartSimProbability:  ServiceUrl + '/chart/simprobability',
	EP_CacheItemPtf:  ServiceUrl + '/cache/itemptf',
	EP_FundAnagraphics: ServiceUrl + '/tab/fundanagraphics',
	EP_FundCosts: ServiceUrl + '/tab/fundcosts',
	EP_ChartPeerScatter: ServiceUrl + '/chart/peerscatter',
	EP_TabLightCompStats: ServiceUrl + '/tab/lightcompstats',
	EP_TabCompBetas: ServiceUrl + '/tab/betascompstats',
	EP_TabCompCorrelations: ServiceUrl + '/tab/corrcompstats',
	EP_PerfRiskRatios: ServiceUrl + '/chart/perf_risk_ratio',
	EP_PeerFunds: ServiceUrl + '/tab/peerfunds',
	EP_PeerStats: ServiceUrl + '/tab/peerstats',
	EP_PeerDistribution: ServiceUrl + '/chart/peerdistribution',
	EP_MarketsStats: ServiceUrl + '/tab/marketstats'
 };

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
