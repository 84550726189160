import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BlockShowToolsComponent } from '../../blocks/block-show-tools/block-show-tools.component';
import { NgRedux} from '@angular-redux/store';
import { ADD_TODO, ENVSTATUS, COOKIE_SET } from '../../actions';
import { IAppState } from '../../store';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';

@Component({
  selector: 'app-module-edit-tools',
  templateUrl: './module-edit-tools.component.html',
  styleUrls: ['./module-edit-tools.component.scss']
})
export class ModuleEditToolsComponent implements OnInit {
  @Input() Newobj;
  @Input() tab;
  StorageArray: any;
  itemsCount:any;
  selectAll = false;
  closeSettings: any;
  constructor(
    private parent: BlockShowToolsComponent,
    private WalletManager: ServiceWalletManagerService,
    private ngRedux: NgRedux<IAppState>
  ) { }

  //Select All 
  updateCheck(){
    if(this.selectAll === true){
      this.Newobj.items.map((item)=>{
        item.checked=true;
      });

    }else {
      this.Newobj.items.map((item)=>{
        item.checked=false;
      });
    }
  }

  //close edit tools bar
  close(){
    this.parent.checked = false;
    this.Newobj.items.map((item)=>{
      item.checked=false;
    });
  }

  RemoveSelected(){
    let storage : any = this.WalletManager.GetWallet();
    this.StorageArray = storage.ptfs;
    let NewToolArray = [];
    this.parent.obj.items.forEach((item, index) => {
      if(item.checked != true){       
        NewToolArray.push(item);
      }
      this.StorageArray[this.tab].items = NewToolArray;

      this.WalletManager.SetWallet(storage);
      
      this.ngRedux.dispatch({
        type: COOKIE_SET, 
        cookie: {
          'WalletNAme': storage
        }
      });
    });
    storage.ptfs[this.tab].nItems = this.StorageArray[this.tab].items.length;
    this.WalletManager.SetWallet(storage);
  }
 
  ngOnInit() {
   
  }

}
