import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ServiceWalletSaveService } from '../../services/service-wallet-save.service';


@Component({
  selector: 'app-block-viewitem-popup',
  templateUrl: './block-viewitem-popup.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./block-viewitem-popup.component.scss']
})
export class BlockViewitemPopupComponent implements OnInit {
  indexWallet : any;
  Labels: any;
  selectedIndex: any;
  selectedYearIndex: any;
  WalletId: any;
  include: boolean;
  freq:any;
  id:any;
  compId:any;
  startDate:any;
  endDate:any;
  slug:any;
  name:any;
  code:any;
  freqsLabels:any;
  years:any;
  yearsCheck:any;
  category:any;
  yearsAgo:any;
  selectedLabel:any;
  constructor(
    public dialog: MatDialog,
    public doCall: ServiceDoCallService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<BlockViewitemPopupComponent>
  ) { 
    this.freqsLabels = {"B":"daily","W-FRI":"weekly (fridays)","SM":"bi-weekly","BM":"monthly","BQ":"quarterly","BA":"yearly"};
    this.years = ["1y","2y","3y","5y","Max"];
    if(this.yearsAgo == undefined)
      this.yearsAgo = 1;
    if(this.selectedYearIndex == undefined)
      this.selectedYearIndex = 0;
    if(this.selectedLabel == undefined)
      this.selectedLabel = "1y";
    this.include = false;
  }

  TabClick(event){ 
    this.include = true;
  }

  TabYearClick(event){
    let label = event.tab.textLabel;
    if(label=="1y")
      this.yearsAgo = 1;
      else if(label=="2y")
      this.yearsAgo = 2;
    else if(label=="3y")
      this.yearsAgo = 3;
    else if(label=="5y")
      this.yearsAgo = 5;
    else if(label=="Max")
      this.yearsAgo = 50;

    this.selectedYearIndex = event.index;
    this.selectedLabel = event.tab.textLabel;

    this.ngOnInit();
  }

  ngOnInit() {
    let params = {"slug":this.slug,"yearsAgo":this.yearsAgo};
    this.doCall.Post(environment.EP_CacheItemPtf,params).subscribe(result => {
    this.Labels = [
      {
        'tab':'Snaphost', 
        'api': [
                {
                  'endpoint': environment.EP_ChartNav,
                  'chart': 'stackedareaseries',
                  'label': 'Performance',
                },
                {
                    'endpoint': environment.EP_FundAnagraphics,
                    'chart': 'fundanagraphics',
                    'label': 'Anagraphics',
                }
                ]
      },
      {
        'tab':'Performance', 
        'api': [
                  {
                    'endpoint': environment.EP_ChartNav,
                    'chart': 'stackedareaseries',
                    'label': 'Performance',
                  },
                  {
                    'endpoint': environment.EP_TabRet,
                    'chart': 'tableRets',
                    'label' : 'Monthly returns',
                  },
                  {
                    'endpoint': environment.EP_DistRet,
                    'chart': 'areasplineseries',
                    'label' : 'Distribution of returns',
                  },
                  {
                    'endpoint': environment.EP_Drawdowns,
                    'chart': 'drawdowns',
                    'label' : 'Drawdowns',
                  }
                ]
      },
      {
        'tab':'Stats', 
        'api': [
          {
            'chart': 'list',
            'label': 'Stats',
            'endpoint': environment.EP_TabStats
          }
        ]
      },
      {
        'tab':'Betas',
        'api': [
          {
            'chart': 'correlations',
            'label': 'Correlations',
            'endpoint': environment.EP_Correlations
          },
          {
            'chart': 'correlationsTab',
            'label': 'Year-by-year correlations',
            'endpoint': environment.EP_YbyCorrelations
          },
          {
            'chart': 'singleBetas',
            'label': 'Betas',
            'endpoint': environment.EP_SingleBetas
          },
          {
            'chart': 'singleBetasTab',
            'label': 'Year-by-year Betas',
            'endpoint': environment.EP_YbySingleBetas
          },
          {
            'chart': 'betas',
            'label': 'Multiple Betas',
            'endpoint': environment.EP_Betas
          },
          {
            'chart': 'betasTab',
            'label': 'Year-by-year Multiple Betas',
            'endpoint': environment.EP_YbyBetas
          }
        ]
      }
    ];

   
      this.id = result["id"];
      this.compId = result["compId"];
      this.category = result["category"]
      this.startDate = result["startDate"];
      this.endDate = result["endDate"];
      this.freq = this.freqsLabels[result["frequence"]];
      if(this.compId != ""){
        this.Labels.push(
        {
          'tab':'Peer',
          'api': [
            {
              'chart': 'peernav',
              'label': 'Performance vs Peer ' + this.category,
              'endpoint': environment.EP_ChartCompNav
            },
            {
              'chart': 'fundcosts',
              'label': 'Costs vs Peer ' + this.category,
              'endpoint': environment.EP_FundCosts
            },
            {
              'chart': 'peerstats',
              'label': 'Portfolio vs Comp',
              'endpoint': environment.EP_TabCompStats
            }
          ]
        },
        {
          'tab':'Scatter', 
          'api': [
                  {
                    'endpoint': environment.EP_ChartPeerScatter,
                    'chart': 'scatterchart',
                    'label': 'Peer group comparison',
                  }
                  ]
        }
        ) 
    } 
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
