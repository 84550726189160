import { Component, OnInit, HostListener  } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../store';
import { ADD_TODO, ENVSTATUS, COOKIE_SET } from '../../actions';
import { MatBottomSheet } from '@angular/material';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';


@Component({
  selector: 'app-block-add-wallet-popup',
  templateUrl: './block-add-wallet-popup.component.html',
  styleUrls: ['./block-add-wallet-popup.component.scss']
})
export class BlockAddWalletPopupComponent implements OnInit {
  @select() Appstate;
  @select() lastUpdate;

  name = 'Choose a name for your portfolio';
  description = '';
  add = 'Add';
  
  constructor(
    private bottomSheet: MatBottomSheet,
    public cookieService: CookieService,
    private WalletManager: ServiceWalletManagerService,
    private ngRedux: NgRedux<IAppState>
  ) {}
  
  setWalletNAme(value): void {
    
    //if value is not empty 
    if(value != ''){
      var wallets = {'ptfs':[]};
      let storage: any = this.WalletManager.GetWallet();

      //if Wallet not exist
      let date = new Date();
      let dd = date.getDate();
      let mm = date.getMonth()+1; 
      let n = date.getHours();
      let y = date.getFullYear();
      let min = date.getMinutes();
      let s = date.getSeconds();
      let newdate = `${dd}${mm}${n}${y}${min}${s}`;

      if(!storage){
        wallets.ptfs.unshift({
          //'id': newdate,
          'name' : value,
          'description':'',
          'id':'',
          'compId':'',
          'simId':'',
          'items': '',
          'nItems':0,
          'options':{},
          'settings': {
            'weightsOptions':{
              'weightsMode' : 'EqualWeights'
            },
            'rebalanceOptions':{
              'rebalanceMode' : 'NoRebalancer'
            },
            'simOptions':{
              'volFactor' : 100,
              'sharpeFactor' : 1,
              'simNumber' : 500,
              'simYearsNumber' : 5
            }
          }}); 
        this.WalletManager.SetWallet(wallets);
      }

      //if Wallet exist add items in array
      else{
        storage.ptfs.unshift({
          'name' : value, 
          'description':'',
          'id':'',
          'compId':'',
          'simId':'',
          'items': '',
          'nItems':0,
          'options':{},
          'settings': {
            'weightsOptions':{
              'weightsMode' : 'EqualWeights'
            },
            'rebalanceOptions':{
              'rebalanceMode' : 'NoRebalancer'
            },
            'simOptions':{
              'volFactor' : 100,
              'sharpeFactor' : 1,
              'simNumber' : 500,
              'simYearsNumber' : 5
            }
          }}); 
        this.WalletManager.SetWallet(storage);
      }
      this.ngRedux.dispatch({
        type: COOKIE_SET, 
        cookie: {
          'WalletNAme': storage
        }
      });
    }
  }

  closeBottomSheet(): void {
    this.bottomSheet.dismiss();
  }

  onKeydown(value) {
    this.setWalletNAme(value);
    this.closeBottomSheet();
  }

  ngOnInit() {

  }

}
