import { Component, OnInit, Input, Inject } from '@angular/core';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { environment } from 'src/environments/environment';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../store';
import { COOKIE_SET } from '../../actions';
import { ServiceWalletSaveService } from '../../services/service-wallet-save.service';
import { MatDialogRef } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material'
import { Router } from '@angular/router';

@Component({
  selector: 'app-block-compare-popup',
  templateUrl: './block-compare-popup.component.html',
  styleUrls: ['./block-compare-popup.component.scss']
})
export class BlockComparePopupComponent implements OnInit {
  myPtfs:any;
  params:any;
  refIndex:any;
  constructor(
    public doCall: ServiceDoCallService,
    public cache: ServiceWalletSaveService,
    private WalletManager: ServiceWalletManagerService,
    private ngRedux: NgRedux<IAppState>,
    public dialogRef: MatDialogRef<BlockComparePopupComponent>,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.refIndex = this.data.refIndex;
   }

  ngOnInit() {
    this.doCall.Post(environment.EP_MyPtf, {}).subscribe(result => {this.myPtfs = result;});
  }

  comparePtf(tool){
    let existingStorage = this.WalletManager.GetWallet();
    let options =  {"slug":tool.slug};
    this.doCall.Post(environment.EP_MyPtfParams, options).subscribe(
      res => {
        this.params = res;
        
        if(this.params["settings"]["weightsOptions"]["volConstraint"] == 0)
        this.params["settings"]["weightsOptions"]["volConstraint"] = "";
        else
        this.params["settings"]["weightsOptions"]["volConstraint"] = this.params["settings"]["weightsOptions"]["volConstraint"] *100;

        let i = 0;
        if(this.params["settings"]["weightsOptions"]["bounds"] == undefined)
          this.params["settings"]["weightsOptions"]["bounds"] = "bounded";
        if(this.params["settings"]["weightsOptions"]["bounds"] != "bounded"){
          if(this.params["settings"]["weightsOptions"]["bounds"].length > 1){
            this.params["settings"]["weightsOptions"]["bounds"].forEach((element) => {
              this.params["settings"]["weightsOptions"]["bounds"][i] = [element[0]*100,element[1]*100];
            i++;
            })
          }
        }
         
        if(existingStorage == undefined){
          existingStorage = {};
          existingStorage["ptfs"] = [];
        }
        if(existingStorage["ptfs"].length == 0){
          existingStorage["ptfs"] = [];
        }
        existingStorage["ptfs"].push( this.params);
        this.WalletManager.SetWallet(existingStorage);
        this.cache.CacheSinglePortfolio(existingStorage["ptfs"].length-1,false,this.refIndex);
        this.ngRedux.dispatch({
          type: COOKIE_SET, 
          cookie: {
            'WalletNAme': this.WalletManager.GetWallet()
          }
        });
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
