import { Component, OnInit } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { COOKIE_SET } from '../../actions';
import { Router } from '@angular/router';
import { IAppState } from '../../store';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { environment } from 'src/environments/environment';
import { ServiceAuthService } from '../../services/service-auth.service';

@Component({
  selector: 'app-module-my-area',
  templateUrl: './module-my-area.component.html',
  styleUrls: ['./module-my-area.component.scss']
})
export class ModuleMyAreaComponent implements OnInit {
  myPtfs: any;
  myCart: any;
  myMarked: any;
  params:any;
  tabs:any;
  fundsReport:any;
  idUser:any;
  constructor(
    public doCall: ServiceDoCallService,
    public auth: ServiceAuthService,
    private ngRedux: NgRedux<IAppState>,
    private WalletManager: ServiceWalletManagerService,
    private router: Router
    ) {
      this.tabs = ["My Portfolios","Marked"];
      this.fundsReport = ['http://podessys.anthilia.it/funds_report/report.php'];
     }

  ngOnInit() {
    this.auth.RedirectToLogin();
    this.doCall.Post(environment.EP_MyPtf, {}).subscribe(result => {this.myPtfs = result;});
    this.doCall.Post(environment.EP_MyCart, {}).subscribe(result => {this.myCart = result;});
    this.doCall.Post(environment.EP_MyMarked, {}).subscribe(result => {this.myMarked = result});
    this.idUser = this.auth.GetUserInfo().id_user;
  }

  editPtf(tool){
    let options =  {"slug":tool.slug};
    this.doCall.Post(environment.EP_MyPtfParams, options).subscribe(
      res => {
        this.params = res;
        
        if(this.params["settings"]["weightsOptions"]["volConstraint"] == 0)
        this.params["settings"]["weightsOptions"]["volConstraint"] = "";
        else
        this.params["settings"]["weightsOptions"]["volConstraint"] = this.params["settings"]["weightsOptions"]["volConstraint"] *100;

        let i = 0;
        if(this.params["settings"]["weightsOptions"]["bounds"] == undefined){
          this.params["settings"]["weightsOptions"]["bounds"] = "bounded";
        }
        if(this.params["settings"]["weightsOptions"]["bounds"] != "bounded"){
          if(this.params["settings"]["weightsOptions"]["bounds"].length > 1){
            this.params["settings"]["weightsOptions"]["bounds"].forEach((element) => {
              this.params["settings"]["weightsOptions"]["bounds"][i] = [element[0]*100,element[1]*100];
            i++;
            })
          }
        }

        let storage:any = this.WalletManager.GetWallet();
        if(storage == undefined)
          storage = {};
          storage["ptfs"] = [];
        if(storage["ptfs"].length == 0)
          storage["ptfs"] = [];

        if(this.params.options == undefined){
          this.params.options = {};
          this.params.options.frequence = this.params["settings"]["weightsOptions"]["frequence"];
        }

        storage["ptfs"].unshift( this.params);
        this.WalletManager.SetWallet(storage);
        this.ngRedux.dispatch({
          type: COOKIE_SET, 
          cookie: {
            'WalletNAme': this.WalletManager.GetWallet()
          }
        });
        this.router.navigate(['dashboard']);
      });
  }

  deletePtf(tool){
    let options =  {"slugs":[tool.slug]};
    if(window.confirm('Are sure you want to delete this item ?')){
    this.doCall.Post(environment.EP_DeletePtf, options).subscribe(
      res => {
        let deleted = res;
        this.doCall.Post(environment.EP_MyPtf, {}).subscribe(result => {this.myPtfs = result});
      });
    }
  }

 removeCart(tool){
    let options =  {"slugs":[tool.slug]};
    this.doCall.Post(environment.EP_DeleteCart, options).subscribe(
      res => {
        let deleted = res;
        this.doCall.Post(environment.EP_MyCart, {}).subscribe(result => {this.myCart = result});
      });
  }

  removeMarked(tool){
    let options =  {"slugs":[tool.slug]};
    this.doCall.Post(environment.EP_DeleteMarked, options).subscribe(
      res => {
        let deleted = res;
        this.doCall.Post(environment.EP_MyMarked, {}).subscribe(result => {this.myMarked = result});
      });
  }

  onSearchChange(searchValue:string) {
    let text = searchValue.toLowerCase();
    let params =  {"s": text};
    this.doCall.Post(environment.EP_MyPtf, params).subscribe(result => {this.myPtfs = result;});
  }
}