import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ServiceAuthService } from '../../services/service-auth.service';

@Component({
  selector: 'app-module-login',
  templateUrl: './module-login.component.html',
  styleUrls: ['./module-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleLoginComponent implements OnInit {
  constructor(
    private Login: ServiceAuthService
  ) { }

  LoginUser(user,password){
    this.Login.DoLogin(user,password);
  }

  ngOnInit() {
    this.Login.RedirectUser();
  }
}
