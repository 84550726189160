import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from 'src/app/services/service-wallet-manager.service';

@Component({
  selector: 'app-chart-list',
  templateUrl: './chart-list.component.html',
  styleUrls: ['./chart-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ChartListComponent implements OnInit {
  @Input()chart;
  @Input() indexWallet;
  @Input() id;
  @Input() compId;
  breakpoint:any;
  breakpoint_col:any;
  breakpoint_col_list:any;
  list: any;
  selectedItem: any;
  selectedItems: any;
  options:any;
  endpoint:any;
  labels: any;
  clicked: any;
  ptfCurr:any;
  datastorage: boolean;
  data_chart: any;
  number: number;
  Math: any;
  endpointDonut = environment.EP_ChartWeights;
  constructor(
    private doCall: ServiceDoCallService,
    private WalletManager: ServiceWalletManagerService
  ) { 
    this.Math = Math;
    this.number = 2.5;
    this.selectedItems = [];
    this.clicked = [];
    this.endpoint = [];
    this.labels = [];
    this.options = [];
    this.ptfCurr = "EUR";
  }
  public reset: any[] = [{}];
 
  listClick(event, newValue) {
    if(this.selectedItems.includes(newValue)){
      this.selectedItems.splice(this.selectedItems.indexOf(newValue),1);
    }
    else{
      if(this.selectedItems.length == 2){
        this.selectedItems.pop();
      }
      this.selectedItem = newValue;
      this.selectedItems.unshift(newValue);
    }
  }

  GetData(){
    this.reset[0] = {};
    let storage:any = this.WalletManager.GetWallet();
    let id = this.id;
    let compId = this.compId;
    let simId = "";
    let label=[];

    if(storage != undefined){
      if(!id && storage.ptfs[this.indexWallet] != undefined){
        id = storage.ptfs[this.indexWallet].id;
        simId = storage.ptfs[this.indexWallet].simId;
      }
      if(!compId && storage.ptfs[this.indexWallet] != undefined){
        compId = storage.ptfs[this.indexWallet].compId;
      }
      else{
        label = ["Fund"];
      }
    }

    for (let i in this.selectedItems) {
      if(this.chart.label == "Stats"){
        this.endpoint[i] = environment.EP_RollStat;
        this.options[i] = {'id': id, 'stat': this.selectedItems[i].stat,"ptfCurr":this.ptfCurr};
        this.labels[i] = this.selectedItems[i].label + " (rolling)";
      }
      else if(this.chart.label == "Portfolio vs Comp"){
          this.endpoint[i] = environment.EP_RollStat;
          this.options[i] = {'id': id, 'compId': compId,'stat': this.selectedItems[i].stat,"label":label};
          this.labels[i] = this.selectedItems[i].label + " (rolling)";
      }
      else if(this.chart.label == "Simulated Stats Distribution"){
            this.endpoint[i] = environment.EP_DistSimStats;
            this.options[i] = {'id': id, 'simId': simId,'stat': this.selectedItems[i].stat};
            this.labels[i] = this.selectedItems[i].label + " (distribution)";
      }else{
        this.endpoint[i] = environment.EP_WeightEvolution;
        this.options[i] = {'id': id, 'signature': this.selectedItems[i].signature};
        this.labels[i] = this.selectedItems[i].label + ' weight';
      } 
    }
  }

  ngOnInit() {
    this.clicked = false;
    let storage:any = this.WalletManager.GetWallet();
    let id = this.id;
    let compId = this.compId;
    let simId = "";
    if(storage != undefined){
      if(!id && storage.ptfs[this.indexWallet] != undefined){
        id = storage.ptfs[this.indexWallet].id;
        simId = storage.ptfs[this.indexWallet].simId;
      }
      if(!compId && storage.ptfs[this.indexWallet] != undefined){
        compId = storage.ptfs[this.indexWallet].compId;
      }
    }
    function onlyUnique(value, index, self) { 
      return self.indexOf(value) === index;
    }
    if(!id){
      let currencies = [];
      storage["ptfs"][this.indexWallet].items.forEach((element) => {
        currencies.push(element.curr)
      });
      
      var uniqueCurrencies = currencies.filter( onlyUnique );
      if(uniqueCurrencies.length == 1)
        this.ptfCurr = uniqueCurrencies[0];
      else
        this.ptfCurr = "EUR";
    }
    else{
      this.ptfCurr = "EUR";
    }
    let options =  {"id": id,'compId': compId,"simId": simId, "ptfCurr":this.ptfCurr};
    this.doCall.Post(this.chart.endpoint, options).subscribe(res => {
        this.list = res;
        if(this.chart.label == "Simulated Stats Distribution"){
          this.listClick("",res[2]);
          this.listClick("",res[6]);
          this.GetData();
        }
        else if(this.chart.label == "Stats"){
          this.listClick("",res[3]);
          this.listClick("",res[6]);
          this.GetData();
        }
        else if(this.chart.label == "Portfolio vs Comp"){
          this.listClick("",res[2]);
          this.listClick("",res[7]);
          this.GetData();
        }
      });
    this.breakpoint = (window.innerWidth <= 450) ? 1 : 7;
    this.breakpoint_col = (window.innerWidth <= 451) ? 1 : 5;
    this.breakpoint_col_list = (window.innerWidth <= 451) ? 1 : 2;
    if(this.chart.label == "Simulated Stats Distribution"){
      this.breakpoint_col_list = (window.innerWidth <= 451) ? 1 : 3;
      this.breakpoint_col = (window.innerWidth <= 451) ? 1 : 4;
    }
  }

  onResize(event) {   
    this.breakpoint = (event.target.innerWidth <= 450) ? 1 : 7;
    this.breakpoint_col = (window.innerWidth <= 451) ? 1 : 5;
    this.breakpoint_col_list = (window.innerWidth <= 451) ? 1 : 2;
    if(this.chart.label == "Simulated Stats Distribution"){
      this.breakpoint_col_list = (window.innerWidth <= 451) ? 1 : 3;
      this.breakpoint_col = (window.innerWidth <= 451) ? 1 : 4;
    }
  }
}
