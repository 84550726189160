import { Component, OnInit, Input, AfterViewInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { jqxChartComponent } from '../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { ServiceExcelExportService } from '../../services/service-excel-export.service';

@Component({
  selector: 'app-chart-scatter',
  templateUrl: './chart-scatter.component.html',
  styleUrls: ['./chart-scatter.component.scss']
})
export class ChartScatterComponent implements OnInit {
  @Input() endpoint;
  @Input() label;
  @Input() slug;
  @Input() category;
  @Input() timeIndex;
  display:any;
  option:any;
  data:any;
  xAxisStat:any;
  yAxisStat:any;
  lowTreshold:any;
  upTreshold:any;
  xStatsList: any = [
   "Volatility","MaxDrawdown","ParametricExpShortfall","HistoricVar","Alpha","Beta","SharpeRatio","AvgReturnsAnnual"
  ];
  xStatsLabels: any = [
    "Volatility","Max Drawdown","Expected Shortfall","Monthly Var","Alpha vs Peer average","Beta vs Peer average","Sharpe Ratio","Annual Return"
  ];
  yStatsList: any = [
    "AvgReturnsAnnual","SharpeRatio","Beta","Alpha","HistoricVar","ParametricExpShortfall","MaxDrawdown","Volatility"
  ];
  yStatsLabels: any = [
    "Annual Return","Sharpe Ratio","Beta vs Peer average","Alpha vs Peer average","Monthly Var","Expected Shortfall","Max Drawdown","Volatility"
  ];
  lowThresholds: any = [
    1,50,100,500,1000,5000
  ];
  lowThresholdsLabels: any = [
    " - ","> 50 mln","> 100 mln","> 500 mln","> 1000 mln","> 5000 mln"
  ];
  upThresholds: any = [
    1000000000000,5000,1000,500,100,50
  ];
  upThresholdsLabels: any = [
    " - ","< 5000 mln","< 1000 mln","< 500 mln","< 100 mln","< 50 mln"
  ];
  

  @ViewChild('myChart') myChart: jqxChartComponent;
  constructor(
    private doCall: ServiceDoCallService,
    private excelExport:ServiceExcelExportService
  ) { 
    this.display = false;
    this.xAxisStat = this.xStatsList[0];
    this.yAxisStat = this.yStatsList[0];
    this.lowTreshold = this.lowThresholds[0];
    this.upTreshold = this.upThresholds[0];
  }

    sampleData: any[] = [];

    padding: any = { left: 5, top: 5, right: 5, bottom: 5 };
    titlePadding: any = { left: 90, top: 0, right: 0, bottom: 10 };

    getWidth() : any {
      
        return '97%';
     
    }
	
    xAxis: any = {};
    valueAxis: any ={};

    seriesGroups: any[] =
    [
        {
            type: 'scatter',
            series: []
        }
    ];

    getData(xAxisStatInput,yAxisStatInput,lowThresholdValue,upTresholdValue){
      let url = this.endpoint;
      let tail = 1;
      this.sampleData = [];
      this.display = true;
      switch(this.timeIndex) { 
        case 0: { 
          tail = 1;
          break; 
        } 
        case 1: { 
          tail = 2;
          break; 
        }
        case 2: { 
          tail = 3;
        break; 
        } 
        case 3: { 
          tail = 4;
        break; 
        } 
        case 4: { 
          tail = 5;
        break; 
        } 
        case 5: { 
          tail = 50;
        break; 
        }  
    } 
      this.option =  {"slug": this.slug,"category":this.category,"tail": tail,"xAxisStat":xAxisStatInput,"yAxisStat":yAxisStatInput, "lowAumTreshold": lowThresholdValue, "upAumTreshold": upTresholdValue};
      this.doCall.Post(url, this.option).subscribe(res => {
        this.data = res;
        let slugs = [];
        let names = [];
        let aums = [];
        let currencies = [];
        let selectedSlug = this.slug;

        let counter=0;
        let xDataField = "x " + xAxisStatInput;
        let yDataField = "y " + yAxisStatInput;
        let xDataLabel = "x " + this.xStatsLabels[this.xStatsList.indexOf(xAxisStatInput)];
        let yDataLabel = "y " + this.yStatsLabels[this.yStatsList.indexOf(yAxisStatInput)];
        this.data["x"].forEach(element => {
          this.sampleData.push({ [xDataField] :element, [yDataField]:this.data["y"][counter]})
          slugs.push(this.data["slugs"][counter]);
          names.push(this.data["names"][counter]);
          aums.push(this.data["aums"][counter]);
          currencies.push(this.data["currencies"][counter]);
          counter = counter + 1;
        });

        this.seriesGroups[0]["series"].push(
          { dataField: yDataField, symbolSize: 10, symbolType: 'circle', displayText: yDataLabel,
                    colorFunction: function (value, index) {
                      if (slugs[index] == selectedSlug) {
                          return '#CC4401';
                      }
                      else{
                        return "#5f9c9f";
                      }
                    },
                    formatFunction: function (value, index) {
                        return yDataLabel + ' : ' + value + "<br>" + "Name:" + names[index] + "<br>" + "Aum:" + aums[index] + " mln " + currencies[index];
                    }
          }
        )
        
        let timer = setTimeout(() => {
          this.myChart.xAxis().dataField = xDataField;
          this.myChart.xAxis().displayText = xDataLabel;
          this.myChart.xAxis().title = { text: xDataLabel + " <br>" };
          this.myChart.xAxis().valuesOnTicks = false;
          if(xAxisStatInput == "Beta"){
            let min = Math.min(...this.data["x"]);
            let max = Math.max(...this.data["x"]);
            if(min<0)
              min = min*1.1;
            else 
              min = min*0.9;
            if(max<0)
              max = max*0.9;
            else 
              max = max*1.1;

            this.myChart.xAxis().minValue = min;
            this.myChart.xAxis().maxValue = max;
            this.myChart.xAxis().flip = false;
          }
          else{
            this.myChart.xAxis().minValue = undefined;
            this.myChart.xAxis().maxValue = undefined;
          }
          this.myChart.valueAxis().dataField = yDataField;
          this.myChart.valueAxis().title = { text: yDataLabel + " <br>" };
          this.myChart.valueAxis().labels = { horizontalAlignment: "right" };
          this.myChart.update();
        }, 1);
      });
    }

    ngOnInit() {
     
    }

    pngExportOnClick() {
      function getExportServer() {
        return 'http://www.jquerygrid.net/export_server/export.php';
      }
      this.myChart.saveAsPNG("donutchart.png",getExportServer());
     };
  
     excelExportOnClick() {
      this.excelExport.exportAsExcelFile(this.sampleData,"data");
     };

    xAxisStatChange(value: any): void {
      this.xAxisStat = value;
    }
    yAxisStatChange(value: any): void {
      this.yAxisStat = value;
    }
    lowTresholdChange(value: any): void {
      this.lowTreshold = value;
    }
    upTresholdChange(value: any): void {
      this.upTreshold = value;
    }
    viewScatter(){
      if(this.endpoint && this.xAxisStat  && this.yAxisStat && this.lowTreshold && this.upTreshold){
        this.getData(this.xAxisStat,this.yAxisStat,this.lowTreshold,this.upTreshold);
      }
    }
}
