import { Component, OnInit, Input } from '@angular/core';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';

@Component({
  selector: 'app-module-proxy',
  templateUrl: './module-proxy.component.html',
  styleUrls: ['./module-proxy.component.scss']
})
export class ModuleProxyComponent implements OnInit {
  @Input() tab;
  proxy: any;
  constructor(
    private WalletManager: ServiceWalletManagerService,
  ){
    this.proxy = false
  }

  onProxyChange(proxy) { 
    let storage: any = this.WalletManager.GetWallet();
    storage["ptfs"][this.tab].options.useProxy = proxy.checked
    this.proxy = proxy.checked
    this.WalletManager.SetWallet(storage);
  }

  ngOnInit() {
    let storage: any = this.WalletManager.GetWallet();
    if(storage["ptfs"][this.tab]){
      this.proxy = storage["ptfs"][this.tab].options.useProxy
    }
  }
}