import { Component, OnInit, Input } from '@angular/core';
import { BlockViewPopupComponent } from '../../blocks/block-view-popup/block-view-popup.component';
import { MatDialog } from '../../../../node_modules/@angular/material';
import { Router } from '@angular/router';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { BlockComparePopupComponent } from '../../blocks/block-compare-popup/block-compare-popup.component';
import { BlockSimulatePopupComponent } from '../../blocks/block-simulate-popup/block-simulate-popup.component';
import { ModuleOutputComponent } from '../module-output/module-output.component';

@Component({
  selector: 'app-module-nav-edit',
  templateUrl: './module-nav-edit.component.html',
  styleUrls: ['./module-nav-edit.component.scss']
})
export class ModuleNavEditComponent implements OnInit {
  @Input() CurrentWallet;
  @Input() indexWallet;
  constructor(
    public dialog: MatDialog, 
    private WalletManager: ServiceWalletManagerService,
    public router: Router,
    
    ) { }

  ngOnInit() {

  }
  
  RemoveID(){
    let storage:any = this.WalletManager.GetWallet();
    storage.ptfs[this.indexWallet].id = '';
    storage.ptfs[this.indexWallet].compId = '';
    storage.ptfs[this.indexWallet].simId = '';
    this.WalletManager.SetWallet(storage);
    if(storage.ptfs[this.indexWallet].id == ''){
      this.router.navigate(['dashboard']);
    }
  }

  openDialogView(): void { 
    this.RemoveID();
    const dialogRefview = this.dialog.open(BlockViewPopupComponent, {
      maxWidth: '90%',
      width: '900px'
    });
    dialogRefview.componentInstance.data= {
      index: this.indexWallet
    };
    dialogRefview.afterClosed().subscribe(result => {
    });
}

openDialogCompare(): void { 
  const dialogRefview = this.dialog.open(BlockComparePopupComponent, {
    maxWidth: '90%',
    height: '700px',
    width: '1600px',
    data:{
      refIndex:this.indexWallet
    }
  });
  dialogRefview.afterClosed().subscribe(result => {
    this.router.navigate(['/view'], { queryParams: { i: this.indexWallet, t: '0' } });
  });
}

openDialogSimulate(): void { 
  const dialogRefview = this.dialog.open(BlockSimulatePopupComponent, {
    maxWidth: '90%',
    height: '800px',
    width: '1600px',
    data:{
      refIndex:this.indexWallet
    }
  });
  dialogRefview.afterClosed().subscribe(result => {
    this.router.navigate(['/view'], { queryParams: { i: this.indexWallet, t: '0' } });
  });
}

}
