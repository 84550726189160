import { Component, OnInit, Input, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { jqxChartComponent } from '../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { environment } from '../../../environments/environment';
import { ServiceExcelExportService } from '../../services/service-excel-export.service';


@Component({
  selector: 'app-chart-bars',
  templateUrl: './chart-bars.component.html',
  styleUrls: ['./chart-bars.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChartBarsComponent implements OnInit {
  @Input() endpoint;
  @Input() indexWallet;
  @Input() label;
  data: any;
  option: any;
  height:number;
  order:any;
  classification:any;
  level:any;
  xAxis : any = {
    dataField: 'label',
    gridLines: {
       visible: false 
      },
    labels: {
      angle: 90 ,
      horizontalAlignment:'right',
      formatFunction : function(str){
        let newString = "";
        let l=35;
        while(str.length > l){
            var pos = str.substring(0, l).lastIndexOf(' ');
            pos = pos <= 0 ? l : pos;
            newString = newString + "<br>" + (str.substring(0, pos));
            var i = str.indexOf(' ', pos)+1;
            if(i < pos || i > pos+l)
                i = pos;
            str = str.substring(i);
        }
        newString = newString + "<br>" + str;
        return newString;
      }
    }
  };
  valueAxis : any = {
    gridLines: { visible: false},
    tickMarks: { visible: false},
    labels:{visible: false},
    flip:true
  };
  seriesGroups: any[] =
  [
    {
      type: 'column',
      orientation: 'horizontal',
      showLabels: true,
      showLegend: false,
      useGradient: false,
      series:
      [{
        dataField: 'value',
        //displayText: 'Contribution',
        color:"#02BAC591"
      }]
    }
  ];
  @ViewChild('myChart')myChart: jqxChartComponent;

  constructor(
    private WalletManager: ServiceWalletManagerService,
    private doCall: ServiceDoCallService,
    private excelExport: ServiceExcelExportService
  ) { }

  getData(sort="default",level=0){
    let storage:any = this.WalletManager.GetWallet(); 
    let url = this.endpoint;
    let id = storage.ptfs[this.indexWallet].id;
    this.order =sort;
    this.level = level;
    let allPositives = true;
    if(this.label != "Performance/Marginal-risk Ratios"){
      this.seriesGroups[0].series[0].formatSettings = { sufix: '%', decimalPlaces: 2}
    }
    else{
      this.seriesGroups[0].series[0].formatSettings = { decimalPlaces: 2}
    }
    this.doCall.Post(environment.EP_Classifications, {}).subscribe(
      result => {this.classification = result[0];
        this.option =  {"id": id,"classification":this.classification,"level":level};
        this.doCall.Post(url, this.option).subscribe(res => {
          let mydata = {};
          let Arr = [];
          for (let key in res) {
            if(res[key]<0)
              allPositives = false;
            mydata = {
              label : key,
              value : res[key]
            }
            Arr.push(mydata);
            this.data = Arr;
          }
          if( this.order=="DESC"){
            this.data.sort(function(obj1, obj2) {
              return obj2.value - obj1.value;
            })
          }
          else if( this.order=="ASC"){
            this.data.sort(function(obj1, obj2) {
              return obj1.value - obj2.value;
            })
          }
          let timer = setTimeout(() => {
            this.myChart.update();
          }, 1);
          this.height = 60*(Arr.length);

          if(allPositives == true)
            this.valueAxis.minValue=0;
          if( this.height < 100){
            this.height = 100;
          }
        });
  });
  }

  pngExportOnClick() {
    function getExportServer() {
      return 'http://www.jquerygrid.net/export_server/export.php';
    }
    this.myChart.saveAsPNG("barchart.png",getExportServer());
   };

   excelExportOnClick() {
    this.excelExport.exportAsExcelFile(this.data,"data");
   };

  ngOnInit() {
    if(this.endpoint){
      this.getData('DESC',1);
    }
    let timer = setTimeout(() => {
      this.myChart.update();
    }, 1);
  }

}
