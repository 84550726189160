import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable({
  providedIn: 'root'
})
export class ServiceWalletManagerService {
  walletKey: string;
  ptfKey: string;
  constructor(
    private locStorage: LocalStorageService,
  ) { 
    this.walletKey = "Wallet",
    this.ptfKey = "ptfs"
  }

  GetWallet(){
    return this.locStorage.get(this.walletKey);
  }

  SetWallet(walletObj){
    return this.locStorage.set(this.walletKey,walletObj);
  }

  DeleteWallet(){
    return this.locStorage.remove(this.walletKey);
  }

  DeletePtf(ptfIndex){
    let wallet = this.GetWallet();
    this.DeleteWallet();
    wallet[this.ptfKey].splice(ptfIndex,1);
    return this.SetWallet(wallet);
  }
  
}