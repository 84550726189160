import { Component, OnInit, setTestabilityGetter,ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Params } from '../../../../node_modules/@angular/router';
import { Router } from '@angular/router';
import { ServiceAuthService } from '../../services/service-auth.service';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { environment } from 'src/environments/environment';
import { BlockSimulatePopupComponent } from '../../blocks/block-simulate-popup/block-simulate-popup.component';
import { MatDialog } from '@angular/material';
import { BlockComparePopupComponent } from '../../blocks/block-compare-popup/block-compare-popup.component';

@Component({
  selector: 'app-module-output',
  templateUrl: './module-output.component.html',
  styleUrls: ['./module-output.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleOutputComponent implements OnInit {
  indexWallet : any;
  CurrentWallet: any;
  Labels: any;
  frequence: any;
  selectedIndex: any;
  WalletId: any;
  include: boolean;
  startDate:any;
  endDate:any;
  freq:any;
  id:any;
  compId:any;
  simId:any;
  targetTab:any;

  constructor(
    private route: ActivatedRoute, 
    private WalletManager: ServiceWalletManagerService, 
    private router: Router,
    private Login: ServiceAuthService,
    public dialog: MatDialog, 
  ) {}

  GetDataGet(){
    this.route.queryParams.subscribe(params => {
      this.indexWallet = params.i;
      this.selectedIndex = params.t;
    });
    return this.indexWallet;
  }

  TabClick(event){ 
    const queryParams: Params = Object.assign({}, this.route .snapshot.queryParams);
    queryParams['t'] = event.index;
    let storage : any = this.WalletManager.GetWallet();
    this.id =  storage.ptfs[this.indexWallet].id
    this.compId =  storage.ptfs[this.indexWallet].compId
    this.simId =  storage.ptfs[this.indexWallet].simId;
    this.include = false;
    
    if(event.index == 8 && (this.simId == undefined || this.simId == '')){
      //alert("Click on 'Simulate' button in the top-right corner to simulate your portfolio");
      this.openDialogSimulate();
    }
    else if(event.index == 7 && (this.compId == undefined || this.compId == '')){
      this.openDialogCompare();
      //alert("Click on 'Compare' button in the top-right corner to compare your portfolio");
    }
    else{
      this.router.navigate(['/view'], { queryParams: queryParams }).then(()=>{
        this.include = true;
      });
    }
   
  }

  ngOnInit() {
    this.Login.CheckLogin();
    this.include = true;
    
    let storage : any = this.WalletManager.GetWallet();
    this.GetDataGet();
    if(!this.GetDataGet()){
      this.router.navigate(['/']);
    }
   
    this.CurrentWallet = storage.ptfs[this.indexWallet];
    this.startDate = this.CurrentWallet.realStartDate;
    this.endDate = this.CurrentWallet.realEndDate;

    this.compId =  this.CurrentWallet.compId;
    this.simId =  this.CurrentWallet.simId;

    let freqsLabels = {"B":"daily","W-FRI":"weekly (fridays)","SM":"bi-weekly","BM":"monthly","BQ":"quarterly","BA":"yearly"};
    this.freq = freqsLabels[this.CurrentWallet.realFreq]

    this.Labels = [
      /*{
        
        'tab':'Overview', 
        'api': [
                  {
                    'endpoint': environment.EP_ChartNav,
                    'chart': 'stackedareaseries',
                  },
                  {
                    'endpoint': environment.EP_TabRet,
                    'chart': 'tableRets',
                  },
                  {
                    'chart': 'list',
                    'label': 'Stats',
                    'endpoint': environment.EP_TabStats
                  },
                  {
                    'chart': 'ters',
                    'label': 'Total Expense Ratios (T.E.R.)',
                    'endpoint': environment.EP_Ters
                  }
                ]
      },
      */
      {
        'tab':'Performance', 
        'api': [
                  {
                    'endpoint': environment.EP_ChartNav,
                    'chart': 'stackedareaseries',
                    'label': 'Performance' + " " + freqsLabels[this.CurrentWallet.options.frequence],
                  },
                  {
                    'endpoint': environment.EP_TabRet,
                    'chart': 'tableRets',
                    'label' : 'Monthly returns',
                  },
                  {
                    'endpoint': environment.EP_DistRet,
                    'chart': 'areasplineseries',
                    'label' : 'Distribution of ' + freqsLabels[this.CurrentWallet.options.frequence] + ' returns',
                  },
                  {
                    'endpoint': environment.EP_Drawdowns,
                    'chart': 'drawdowns',
                    'label' : 'Drawdowns',
                  }
                ]
      },
      {
        'tab':'Stats', 
        'api': [
          {
            'chart': 'list',
            'label': 'Stats',
            'endpoint': environment.EP_TabStats
          }
        ]
      },
      {
        'tab':'Weights', 
        'api': [
          {
            'chart': 'list',
            'label': 'Items',
            'endpoint': environment.EP_TabWeights
          },
          {
            'chart': 'donut',
            'label' : 'Weights',
            'endpoint': environment.EP_ChartWeights
          },
          {
            'chart': 'weights',
            'label' : 'Weights Details',
            'endpoint': environment.EP_WeightsSummary
          }
        ]
      },
      {
        'tab':'Contribution',
        'api': [
          {
            'chart': 'perfbars',
            'label': 'Performance',
            'endpoint': environment.EP_PerfContrib
          },
          {
            'chart': 'riskbars',
            'label' : 'Risk',
            'endpoint': environment.EP_RiskContrib
          },
          /*
          {
            'chart': 'perfriskbars',
            'label' : 'Performance/Marginal-risk Ratios',
            'endpoint': environment.EP_PerfRiskRatios
          }*/
        ]
      },
      {
        'tab':'Betas',
        'api': [
          {
            'chart': 'correlations',
            'label': 'Correlations',
            'endpoint': environment.EP_Correlations
          },
          {
            'chart': 'correlationsTab',
            'label': 'Year-by-year correlations',
            'endpoint': environment.EP_YbyCorrelations
          },
          {
            'chart': 'singleBetas',
            'label': 'Betas',
            'endpoint': environment.EP_SingleBetas
          },
          {
            'chart': 'singleBetasTab',
            'label': 'Year-by-year Betas',
            'endpoint': environment.EP_YbySingleBetas
          },
          {
            'chart': 'betas',
            'label': 'Multiple Betas',
            'endpoint': environment.EP_Betas
          },
          {
            'chart': 'betasTab',
            'label': 'Year-by-year Multiple Betas',
            'endpoint': environment.EP_YbyBetas
          }
        ]
      },
      {
        'tab':'Diversification',
        'api': [
          {
            'chart': 'diversification',
            'label': 'Risk-Diversification Effect',
            'endpoint': environment.EP_Diversification
          },
          {
            'chart': 'internalCorrelations',
            'label': 'Internal Correlations',
            'endpoint': environment.EP_InternalCorrelations
          }
        ]
      },
      {
        'tab':'Costs',
        'api': [
          {
            'chart': 'ptfcosts',
            'label': 'Portfolio Estimated Fees',
            'endpoint': environment.EP_PortfolioCosts
          },
          {
            'chart': 'mgmtfees',
            'label': 'Management Fees Contribution ',
            'endpoint': environment.EP_MgmtFees
          },
          {
            'chart': 'ters',
            'label': 'Total Expense Ratios (T.E.R.) Contribution',
            'endpoint': environment.EP_Ters
          },
          {
            'chart': 'fundscosts',
            'label': 'Funds Fees',
            'endpoint': environment.EP_Costs
          }
        ]
      },
      {
        'tab':'Compare',
        'api': [
          {
            'chart': 'compnav',
            'label': 'Nav Comparation',
            'endpoint': environment.EP_ChartCompNav
          },
          {
            'endpoint': environment.EP_TabRet,
            'chart': 'tableRetsPort',
            'label' : 'Portofolio Monthly returns',
          },
          {
            'endpoint': environment.EP_TabRet,
            'chart': 'tableRetsComp',
            'label' : 'Comp Monthly returns',
          },
          {
            'chart': 'tableStats',
            'label': 'Stats',
            'endpoint': environment.EP_TabLightCompStats
          },
          {
            'chart': 'compCorrelations',
            'label': 'Correlations',
            'endpoint': environment.EP_TabCompCorrelations
          },
          {
            'chart': 'compBetas',
            'label': 'Betas',
            'endpoint': environment.EP_TabCompBetas
          },
          {
            'chart': 'ptfcompCosts',
            'label': 'Portfolio Estimated Fees',
            'endpoint': environment.EP_PortfolioCosts
          },
          {
            'chart': 'list',
            'label': 'Portfolio vs Comp',
            'endpoint': environment.EP_TabCompStats
          }
        ]
      },
      {
        'tab':'Simulate',
        'api': [
          /*{
            'endpoint': environment.EP_ChartSimBounds,
            'chart': 'simportfoliosbounds',
            'label': 'Performance boundaries'
          },*/
          {
            'endpoint': environment.EP_ChartSimNav,
            'chart': 'simportfolios',
            'label': 'Performance simulation'
          },
          {
            'endpoint': environment.EP_TabSimPctlStats,
            'chart': 'simpctlstats',
            'label': 'Simulated Stats'
          },
          {
            'endpoint': environment.EP_TabSimStats,
            'chart': 'simstats',
            'label': 'Simulated Stats Distribution'
          },
          {
            'endpoint': environment.EP_ChartSimProbability,
            'chart': 'simportfoliosprobability1',
            'label': ''
          },
          {
            'endpoint': environment.EP_ChartSimProbability,
            'chart': 'simportfoliosprobability2',
            'label': ''
          },
          {
            'endpoint': environment.EP_ChartSimProbability,
            'chart': 'simportfoliosprobability3',
            'label': ''
          },
          {
            'endpoint': environment.EP_ChartSimProbability,
            'chart': 'simportfoliosprobability4',
            'label': ''
          }
        ]
      }
      
    ];
  }

  ngOnUpdate(tab){
    this.ngOnInit();
  }

  openDialogSimulate(): void { 
    const dialogRefview = this.dialog.open(BlockSimulatePopupComponent, {
      maxWidth: '90%',
      height: '800px',
      width: '1600px',
      data:{
        refIndex:this.indexWallet
      }
    });
    dialogRefview.afterClosed().subscribe(result => {
      this.ngOnUpdate("8");
    });
  }

  openDialogCompare(): void { 
    const dialogRefview = this.dialog.open(BlockComparePopupComponent, {
      maxWidth: '90%',
      height: '700px',
      width: '1600px',
      data:{
        refIndex:this.indexWallet
      }
    });
    dialogRefview.afterClosed().subscribe(result => {
      this.ngOnUpdate("7");
    });
  }
}

