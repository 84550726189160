import { ITodo, AppState } from './todo';
import { ADD_TODO, ENVSTATUS, TOGGLE_TODO, REMOVE_TODO, REMOVE_ALL_TODOS, COOKIE_SET, CHANGE_COMPONENT, STORAGE_ID } from './actions';
import {
    createStore,
    Store,
    compose,
    StoreEnhancer
  } from 'redux';


export function rootReducer(state: IAppState, action): IAppState {
    switch (action.type) {
        case COOKIE_SET:
            return Object.assign({}, state, {
                Appstate: state.Appstate.concat(Object.assign({}, action.cookie)),
                lastAction: action.type,
                lastUpdate: new Date()
            })
        case CHANGE_COMPONENT:
        return Object.assign({}, state, {
            Appstate: state.Appstate.concat(Object.assign({}, action.change)),
            lastAction: action.type,
        })  
        case STORAGE_ID:
        return Object.assign({}, state, {
            Appstate: state.Appstate.concat(Object.assign({}, action.change)),
            lastAction: action.type,
        })    
        case ENVSTATUS:
            return Object.assign({}, state, {
                Appstate: state.Appstate.concat(Object.assign({}, action.envstatus)),
                lastUpdate: new Date()
            })

    }
    return state;
}
export interface IAppState {
    Appstate: ITodo[];
    lastAction: String;
    lastUpdate: Date;
}
export const INITIAL_STATE: IAppState = {
    Appstate: [],
    lastAction: 'INITIAL_STATE',
    lastUpdate: null
}

