import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router } from '@angular/router';
import { ServiceDoCallService } from './service-do-call.service';
import { environment } from '../../environments/environment';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class ServiceAuthService {
  error:any;

  constructor(
    private locStorage: LocalStorageService,
    private doCall: ServiceDoCallService,
    private router: Router
  ) { }

  DoLogin(user, password){
    if(user && password && user.value && password.value){
      let options = { "username":user.value, "password":password.value }
      this.doCall.Post(environment.EP_Login, options).subscribe(
          res => {
            if(res){
              this.locStorage.set('Auth', res['token']);
              this.RedirectUser();
            }
          },
          error => {
            this.error = 'impossibile accedere con queste credenziali'
          }
      );
    
    }else{
      let msg = (user.value == '') ? 'user' : 'password';
      alert('il campo ' + msg + ' è vuoto');
    }
  }

  GetUserInfo(){
    let token = this.locStorage.get("Auth");
    return jwt_decode(token);
  }

  RedirectUser(){
    if(this.CheckLogin()){
      this.router.navigate(['home']);
    }
    else{
      this.router.navigate(['/']);
    }
  }

  RedirectToLogin(){
    if(this.CheckLogin()){
    }
    else{
      this.router.navigate(['/']);
    }
  }

  CheckLogin(){
    let auth = this.locStorage.get('Auth');
      if(!auth){
        return false;
      }else{
        return true;
      }
  }

  DoLogout(){
    this.locStorage.remove('Auth');
    this.RedirectUser();
  }
}


