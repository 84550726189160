import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../store';
import { ADD_TODO, ENVSTATUS, COOKIE_SET, STORAGE_ID } from '../actions';
import { ServiceWalletManagerService } from './service-wallet-manager.service';
import { ServiceDoCallService } from './service-do-call.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ServiceWalletSaveService {
  
  constructor(
    public router: Router,
    public doCall: ServiceDoCallService,
    private WalletManager: ServiceWalletManagerService,
    private ngRedux: NgRedux<IAppState>
    ) { }

  CacheSinglePortfolio(index,route=true,refIndex=null){
    let storage : any = this.WalletManager.GetWallet();
    let params = {"ptfs":[storage.ptfs[index]]};
    this.doCall.Post(environment.EP_CachePtf, params).subscribe(result => {
      let id = result[0]["id"];
      let realStartDate = result[0]["startDate"];
      let realEndDate = result[0]["endDate"];
      let realFreq = result[0]["frequence"];
      storage.ptfs[index].id = id;
      storage.ptfs[index].realStartDate = realStartDate;
      storage.ptfs[index].realEndDate = realEndDate;
      storage.ptfs[index].realFreq = realFreq;
      if(refIndex != null){
        storage.ptfs[refIndex].compId = id;
        storage.ptfs.splice(index,1);
      }
      this.WalletManager.SetWallet(storage);
      if(route==true){
        this.ngRedux.dispatch({
          type: STORAGE_ID,
          cookie: {
            'WalletNAme': storage
          }
        })
        this.router.navigate(['/view'], { queryParams: { i: index, t: '0' } });
      }

    });
     error => console.log('There was an error: ')
  }

  CacheSingleSimPortfolio(index){
    let storage : any = this.WalletManager.GetWallet();
    let params = {"ptfs":[storage.ptfs[index]]};
    this.doCall.Post(environment.EP_CacheSimPtf,params).subscribe(result => {
      let simId = result[0]["simId"];
      storage.ptfs[index].simId = simId;
      this.WalletManager.SetWallet(storage);
    });
     error => console.log('There was an error: ')
  }

  SaveSinglePortfolio(index,name,description=""){
    let storage : any = this.WalletManager.GetWallet();
    storage.ptfs[index].options.tail = 25;
    storage.ptfs[index].name = name;
    storage.ptfs[index].description = description;
    storage.ptfs[index].simId = "";
    let params = {"ptfs":[storage.ptfs[index]]};
    this.doCall.Post(environment.EP_SavePtf, params).subscribe(result => {
      let id = result[0];
      return result;
    });
     error => console.log('There was an error: ')
  }
}