import { Component, OnInit, Input } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../store';
import { ADD_TODO, ENVSTATUS, COOKIE_SET } from '../../actions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { environment } from 'src/environments/environment';
import { jqxChartComponent } from '../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { BlockViewitemPopupComponent } from '../block-viewitem-popup/block-viewitem-popup.component';

@Component({
  selector: 'app-block-table-popup',
  templateUrl: './block-table-popup.component.html',
  styleUrls: ['./block-table-popup.component.scss']
})
export class BlockTablePopupComponent implements OnInit {
  type:any;
  title:any;
  classification:any;
  index:any;
  stats:any;
  cumulativeRankings:any;
  lastCumulativeRanking1:any;
  lastCumulativeRanking2:any;
  lastCumulativeRanking3:any;
  ybyRankings:any;
  lastYbyRanking:any;
  environment:any;
  rankingReport:any;
  rankingYbyReport:any;
  peers:any;
  label:any;
  fundsSlugs:any;
  fundsNames:any;
  indicators:any;
  constructor(
    public dialog: MatDialog,
    public doCall: ServiceDoCallService,
    public dialogRef: MatDialogRef<BlockTablePopupComponent>,
  ) { 
    this.rankingReport =  environment.EP_CumulativeRanking;
    this.rankingYbyReport =  environment.EP_YbyRanking;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit() {
    if(this.type == "peer"){
      this.doCall.Post(environment.EP_PeerChartNav, {"classification":this.classification,"category":this.title}).subscribe(
        result => {
          this.index = result["chart"];
          this.stats = result["stats"];
      });
    }
    else if(this.type == "ranking"){
      this.doCall.Get(environment.EP_Rankings, {"classification":this.classification,"category":this.title,"rankingMode":"cumulativeRanking"}).subscribe(
        result => {
          this.cumulativeRankings = result;
          if(this.cumulativeRankings.length>0)
            this.lastCumulativeRanking1 = this.cumulativeRankings[this.cumulativeRankings.length-1]
          if(this.cumulativeRankings.length>1)
            this.lastCumulativeRanking2 = this.cumulativeRankings[this.cumulativeRankings.length-2]
          if(this.cumulativeRankings.length>2)
            this.lastCumulativeRanking3 = this.cumulativeRankings[this.cumulativeRankings.length-3]
      });
      this.doCall.Get(environment.EP_Rankings, {"classification":this.classification,"category":this.title,"rankingMode":"ybyRanking"}).subscribe(
        result => {
          this.ybyRankings = result;
          if(this.ybyRankings.length>0)
            this.lastYbyRanking = this.ybyRankings[this.ybyRankings.length-1]
      });
    }
    else if(this.type == "customRanking"){
      this.doCall.Get(environment.EP_Indicators, {}).subscribe(
        result => {
          this.indicators = result;
      });
    }
  }

}
