import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router } from '@angular/router';
import { ServiceAuthService } from './service-auth.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceDoCallService {
  result: any;
  constructor(
    private http: HttpClient,
    private locStorage: LocalStorageService,
    public router: Router
  ) { }

  private SetHeader(Token){
    let httpOptions = {};
    let contentType = 'application/json';
    if(!Token){
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': contentType
        })
      };
    }
    else{
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': contentType,
          'Token': Token
        })
      };
    }
    
    return httpOptions;
  }

  GetToken(){
    return this.locStorage.get('Auth');
  }

  Post(Url, Params) {
    let response = this.http.post(Url, Params, this.SetHeader(this.GetToken()))
    response.subscribe(res => { },
    (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //A client-side or network error occurred.				 
           //alert('An error occurred: ' + err.error.message);
          } else {
            //Backend returns unsuccessful response codes such as 404, 500 etc.
            if(err.status==409){
              alert('An error ' + err.status + ' occurred: ' + err.error.message);
              this.locStorage.remove('Auth');
              this.router.navigate(['']);	
            }	 
          }
        }
      );

    
    return response;
  }

  Get(Url, Params) {
    let getparams = "?";
    for (let key in Params) {
      getparams = getparams + key + "=" + Params[key] + "&";
    }
    let adjUrl = Url + getparams;
    return this.http.get(adjUrl);
  } 
}
