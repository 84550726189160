import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { jqxChartComponent } from '../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { environment } from '../../../environments/environment';
import { IAppState } from '../../store';
import { NgRedux } from '@angular-redux/store';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { ServiceExcelExportService } from '../../services/service-excel-export.service';

@Component({
  selector: 'app-chart-areasplineseries',
  templateUrl: './chart-areasplineseries.component.html',
  styleUrls: ['./chart-areasplineseries.component.scss']
})
export class ChartAreasplineseriesComponent implements AfterViewInit {
  @Input() endpoint;
  @Input() label;
  @Input() indexWallet;
  @Input() option;
  @Input() id;
  @Input() classification;
  @Input() category;
  @Input() stat;
  @Input() halfwidth;
  @Input() width;
  data: any;
  key: any;
  res: any;
  height:any;
  seriesGroups = [];
  padding: any = { left: 15, top: 35, right: 20, bottom: 5 };
  colors = ['#02BAC514', '#6510B314', '#20250114', '#59157914', '#02C5AF14'];

  constructor(
    private WalletManager: ServiceWalletManagerService,
    private doCall: ServiceDoCallService,
    private excelExport: ServiceExcelExportService,
    private ngRedux: NgRedux<IAppState>
    ) {
            this.ngRedux.subscribe(() => {
            let state = this.ngRedux.getState();
            if(state.lastAction === 'STORAGE_ID'){
              this.getData();
            }
          });
      this.height=320;
    }

  selectColor(){
    var hex = ['0', '1', '2', '3', '4', '5', '6', '7',
    '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'],
    color = '#', i;
    for (i = 0; i < 6 ; i++) {
    color = color + hex[Math.floor(Math.random() * 16)];
    }
   
    return color;
  }
  GetDataChart(chartdata){
    this.key = [];
    let values = [];
    let xvalues = [];
    chartdata.forEach(element => {
        for (let key in element) {
            let value = element[key];
            if(!this.key.includes(key) && key != 'x'){
                this.key.push(key);
            }
        if(key!='x' && key !="normal")
          values.push(element[key])
        if(key=='x')
          xvalues.push(element[key])
        }
        if(this.key.length == 1){
          this.colors = ["#02BAC578"];
        }
    });
    let minValue =  undefined
    let maxValue = undefined
    let unitInterval = undefined
    let xminValue =  undefined
    let xmaxValue = undefined
    let xunitInterval = undefined
    /*
    if(this.key.length > 1){
      minValue =  Math.min(...values);
      maxValue= Math.max(...values);
      let delta = maxValue-minValue;
      unitInterval= Math.ceil(delta/5);
      xminValue =  Math.min(...xvalues);
      xmaxValue= Math.max(...xvalues);
      let xdelta = xmaxValue-xminValue;
      xunitInterval = Math.ceil(xdelta/10);
    }*/
    
    if(this.key.length > 1){
      maxValue= Math.max(...values)*1.01;
    }
    if(this.key.length > 0 ){
        for (let i = 0; i < this.key.length; i++) {
            
            if(this.key[i] == "normal"){
                let item = {
                    type: 'splinearea',
                    useGradient: false,
                    showLegend: true,
                    borderLine: false,
                    series: [
                        { dataField: this.key[i], displayText: this.key[i],  color: this.colors[i]},
                    ],
                    xAxis :
                    {
                        dataField: 'x',
                        minValue: xminValue,
                        maxValue: xmaxValue,
                        type:"basic",
                        thickInterval: xunitInterval,
                        gridLines: { visible: false},
                        labels: { visible: false}
                    },
                    valueAxis:
                    {
                        displayValueAxis: false,
                        labels: { visible: false},
                        axisSize: 'auto',
                        gridLinesColor: '#e7ebef',
                        gridLines: { visible: false},
                        minValue: minValue,
                        maxValue: maxValue,
                        unitInterval: unitInterval,
                        tickMarks: { visible: false}
                    }
                }
                this.seriesGroups.push(item);
            };
            if(this.key[i] == "portfolio" || this.key[i] == "peer funds"){
                let item = {
                    type: 'column',
                    orientation: 'vertical',
                    columnsGapPercent: 100,
                    useGradient: false,
                    showLegend: true,
                    borderLine: false,
                    categoryAxis:
                    {
                        dataField: 'x',
                        type:"basic",
                        showGridLines: false,
                        minValue: xminValue,
                        maxValue: xmaxValue,
                        thickInterval: xunitInterval,
                        showTickMarks: true,
                        flip: false
                    },
                    valueAxis:
                    {
                        flip: false,
                        displayValueAxis: true,
                        minValue: minValue,
                        maxValue: maxValue,
                        unitInterval: unitInterval,
                        showGridLines: false,
                        showTickMarks: true,
                        description: ''
                    },
                    series: [
                        { dataField: this.key[i], displayText: this.key[i],  color: this.colors[i]},
                    ]
                }
                this.seriesGroups.push(item);
            }
        }

    }
    /*
    if(this.key.length > 0 ){
        this.key.forEach(element => {
            count++;
            let item = {
                type: 'splinearea',
                useGradient: false,
                showLegend: true,
                borderLine: false,
                series: [
                    { dataField: element, displayText: element,  color: this.colors[count]},
                ],
                valueAxis:
                {
                    displayValueAxis: true,
                    axisSize: 'auto',
                    gridLinesColor: '#e7ebef',
                    showGridLines: true,
                    
                }
            }
            this.seriesGroups.push(item);
        });
    }
    */
  }

  getData(){
    let storage:any = this.WalletManager.GetWallet();
    let id = this.id;
    let simId = "";
    if(!this.id){
      id = storage.ptfs[this.indexWallet].id;
      simId = storage.ptfs[this.indexWallet].simId;
    }
    if(id != ''){  
        let url = this.endpoint;
        let options =  {"id": id,"stat":this.stat,"classification":this.classification,"category":this.category};
        if(simId && this.option){
          options =  this.option;
        }
    this.doCall.Post(url, options).subscribe(res => {
      this.data = res['chart_data'];
      this.res = res;
      for (let key in this.res) {
       if(key != 'chart_data'){
        let number = this.res[key];
       }
      }
      this.GetDataChart(this.data);
      let data = this.myChart.source();
      let timer = setTimeout(() => {
          this.myChart.update();
      }, 1);
    });
    }
  
  }

  @ViewChild('myChart') myChart: jqxChartComponent;

  ngAfterViewInit(): void  {       
    let storage:any = this.WalletManager.GetWallet();
    let id = this.id;
    if(!this.id){
      id = storage.ptfs[this.indexWallet].id;
    }
    if(this.label){
      if(this.label.substring(this.label.length - 14) =='(distribution)')
      this.height=225;
    }
    if(id != ''){
        this.getData();
    }

  }

  pngExportOnClick() {
    function getExportServer() {
      return 'http://www.jquerygrid.net/export_server/export.php';
    }
    
    this.myChart.saveAsPNG("splinechart.png",getExportServer());
   };

  excelExportOnClick() {
   this.excelExport.exportAsExcelFile(this.data,"data");
  };
}

