import { Component, OnInit, Input, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { jqxChartComponent } from '../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { notDeepEqual } from 'assert';
import { ServiceExcelExportService } from '../../services/service-excel-export.service';


@Component({
  selector: 'app-chart-waterfall',
  templateUrl: './chart-waterfall.component.html',
  styleUrls: ['./chart-waterfall.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChartWaterfallComponent implements OnInit {
  @Input() endpoint;
  @Input() indexWallet;
  @Input() label;
  data: any;
  option: any;
  height:number;
  order:any;
  classification:any;
  level:any;
  xAxis : any = {
    dataField: 'label',
    summary: 'summary',
    gridLines: {
       visible: false 
      },
    labels: {
      angle: 90 ,
      horizontalAlignment:'right',
      formatFunction : function(str){
        let newString = "";
        let l=35;
        while(str.length > l){
            var pos = str.substring(0, l).lastIndexOf(' ');
            pos = pos <= 0 ? l : pos;
            newString = newString + "<br>" + (str.substring(0, pos));
            var i = str.indexOf(' ', pos)+1;
            if(i < pos || i > pos+l)
                i = pos;
            str = str.substring(i);
        }
        newString = newString + "<br>" + str;
        return newString;
      }
    }
  };
  valueAxis : any = {
    gridLines: { visible: false},
    tickMarks: { visible: false},
    labels:{visible: false},
    flip:true
  };
  seriesGroups: any[] =
  [
    {
      type: 'waterfall',
      orientation: 'horizontal',
      showLabels: true,
      showLegend: false,
      useGradient: false,
      series:
      [{
        dataField: 'value',
        summary: 'summary',
        color: "#02BAC561",
        formatSettings: { sufix: '%', decimalPlaces: 2}
      }]
    },
    {
      type: 'column',
      orientation: 'horizontal',
      showLabels: true,
      showLegend: false,
      useGradient: false,
      width:"100px",
      series:
      [
        {dataField: 'total', displayText: 'Total', width:"100px", formatSettings: { sufix: '%', decimalPlaces: 2}, color: "#02BAC591" },
      ]
    },
    {
      type: 'stackedcolumn',
      orientation: 'horizontal',
      showLabels: true,
      showLegend: false,
      useGradient: false,
      series:
      [
        {dataField: 'vol', displayText: 'Portfolio Volatility', formatSettings: { sufix: '%', decimalPlaces: 2}, color:"#ffd55291" },
        {dataField: 'div', displayText: 'Diversification effect', formatSettings: { sufix: '%', decimalPlaces: 2}, color:"#a2ca7e91" },
      ]
    }
  ];
  @ViewChild('myChart')myChart: jqxChartComponent;

  constructor(
    private WalletManager: ServiceWalletManagerService,
    private doCall: ServiceDoCallService,
    private excelExport: ServiceExcelExportService
  ) { }

  getData(sort="default",classification=false,level=0){
    let storage:any = this.WalletManager.GetWallet(); 
    let url = this.endpoint;
    let id = storage.ptfs[this.indexWallet].id;
    this.order =sort;
    this.classification = classification;
    this.level = level;
    this.option =  {"id": id,"classification":classification,"level":level};
    let allPositives = true;
    this.doCall.Post(url, this.option).subscribe(res => {
      let mydata = {};
      let Arr = [];
      for (let key in res) {
        if(key!="Total" && key!="Portfolio"){
          if(res[key]<0)
            allPositives = false;
          mydata = {
            label : key,
            value : res[key],
          }
          Arr.push(mydata);
          this.data = Arr;
        }
      }

      let mytotal = {
        label : "Total",
        total : res["Total"],
      }
      if(!res["Total"]){
        mytotal["summary"] = true;
      }
      Arr.push(mytotal);
      this.data = Arr;

      if(res["Portfolio"]){
        let myptf = {
          label : "Portfolio",
          ptf : res["Portfolio"],
          vol : res["Portfolio"],
          div : mytotal.total-res["Portfolio"]
        }
        Arr.push(myptf);
      }
      this.data = Arr;
      if(this.order=="DESC"){
        this.data.sort(function(obj1, obj2) {
          return obj2.value - obj1.value;
        })
      }
      else if(this.order=="ASC"){
        this.data.sort(function(obj1, obj2) {
          return obj1.value - obj2.value;
        })
      }
      this.height = 54*(this.data.length+1);
      /*if( this.height > 800){
        this.height = 800;
      }*/
      if(allPositives == true)
        this.valueAxis.minValue=0;
      let timer = setTimeout(() => {
        this.myChart.update();
      }, 1);
    });
  }

  ngOnInit() {
    if(this.endpoint){
      this.getData('default',true,1);
    }
    let timer = setTimeout(() => {
      this.myChart.update();
    }, 1);
  }

  pngExportOnClick() {
    function getExportServer() {
      return 'http://www.jquerygrid.net/export_server/export.php';
    }
    this.myChart.saveAsPNG("waterfall.png",getExportServer());
   };

   excelExportOnClick() {
    this.excelExport.exportAsExcelFile(this.data,"data");
   };

}

