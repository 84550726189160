import { Component, OnInit, Input } from '@angular/core';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';

@Component({
  selector: 'app-module-cost',
  templateUrl: './module-cost.component.html',
  styleUrls: ['./module-cost.component.scss']
})
export class ModuleCostComponent implements OnInit {
  @Input() tab;
  cost: any;
  constructor(
    private WalletManager: ServiceWalletManagerService,
  ){
    this.cost = "0"
  }

  onCostChange(costValue : string ) {  
    let storage: any = this.WalletManager.GetWallet();
    storage["ptfs"][this.tab].items.forEach((element) => {
      element.cost = costValue
      this.cost = costValue
    });

    this.WalletManager.SetWallet(storage);
  }

  ngOnInit() {
    let storage: any = this.WalletManager.GetWallet();
    if(storage["ptfs"][this.tab]){
      this.cost = storage["ptfs"][this.tab].items[0].cost
      this.onCostChange(String(storage["ptfs"][this.tab].items[0].cost))
    }
    else  
      this.onCostChange("0")
  }
}