import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from "@angular/core";
import { ModuleOutputComponent } from './modules/module-output/module-output.component';
import { ModuleTabWalletComponent } from './modules/module-tab-wallet/module-tab-wallet.component';
import { ModuleLoginComponent } from './modules/module-login/module-login.component';
import { ModuleHomeComponent } from './modules/module-home/module-home.component';
import { ModuleMyAreaComponent } from './modules/module-my-area/module-my-area.component';
import { ModuleDbComponent } from './modules/module-db/module-db.component';
import { ModuleMarketsComponent } from './modules/module-markets/module-markets.component';


const routes: Routes = [
    { 
        path: 'home', component: ModuleHomeComponent
    },
    { 
        path: 'myarea', component: ModuleMyAreaComponent
    },
    { 
        path: 'database', component: ModuleDbComponent
    },
    { 
        path: 'markets', component: ModuleMarketsComponent
    },
    { 
        path: 'dashboard', component: ModuleTabWalletComponent
    },
    { 
        path: 'itemreport', component: ModuleTabWalletComponent
    },
    { 
        path: 'view', component: ModuleOutputComponent
    },
    { 
        path: 'view/:id', component: ModuleOutputComponent 
    },
    {
        path: '', component: ModuleLoginComponent
    }
];

export const routingModule: ModuleWithProviders = RouterModule.forRoot(routes);

