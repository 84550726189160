import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { MatDialog, MatTableDataSource, MatSort } from '@angular/material';
import { BlockViewpeerPopupComponent } from '../../blocks/block-viewpeer-popup/block-viewpeer-popup.component';
import { BlockViewitemPopupComponent } from '../../blocks/block-viewitem-popup/block-viewitem-popup.component';
import { PeriodicElement } from '../../charts/chart-table/chart-table.component';
import { ServiceExcelExportService } from '../../services/service-excel-export.service';

@Component({
  selector: 'app-module-markets',
  templateUrl: './module-markets.component.html',
  styleUrls: ['./module-markets.component.scss']
})
export class ModuleMarketsComponent implements OnInit {
  categories:any;
  names:any;
  classification:any;
  statValues:any;
  clusters:any;
  DB:any;
  cluster_index:any;
  slug_index:any;
  category_index:any;
  name_index:any;
  peerSlugs:any;
  peers:any;
  tabledata:any;
  dataSource: any;
  slugs:any;
  obj:any;
  lowTreshold:any;
  highTreshold:any;
  meanTreshold:any;
  info:any;
  displayedColumns:any;
  tresholds:any;
  stat:any;
  displayedIndexColumns:any;
  indexData:any;
  indexSlugs:any;
  indexNames:any;
  indexInfo:any;
  ELEMENT_DATA2: PeriodicElement[];
  ELEMENT_INDEX_DATA2: PeriodicElement[];
  indexDataSource:any;
  statsList: any = [
    "PeriodReturn","AvgReturnsAnnual","Volatility","SharpeRatio","MaxDrawdown"
  ];
  statsLabels: any = [
    "Period Returns","Annualized Returns","Volatilities","Sharpe Ratios","Max Drawdowns"
  ];

  @ViewChild("sortpeers") sortpeers: MatSort;
  @ViewChild("sortindexes") sortindexes: MatSort;

  constructor(
    private doCall: ServiceDoCallService,
    private excelExport: ServiceExcelExportService,
    public dialog: MatDialog
  ) 
  { 
    this.clusters = [];
    this.peerSlugs = [];
    this.categories = [];
    this.names = [];
    this.tresholds = [];
    this.peers = {};
    this.info = {};
    this.indexInfo = {};
    this.cluster_index = 3;
    this.slug_index = 4;
    this.category_index = 0;
    this.name_index = 5;
  }

 ngOnInit() {
  this.doCall.Post(environment.EP_Classifications, {}).subscribe(
    res => {this.classification = res[0];
    this.doCall.Get(environment.EP_Categories, {"classification":this.classification,"level":1}).subscribe(
      result => {this.DB = result;
      for (let i in this.DB) {
        this.clusters[i] = this.DB[i][this.cluster_index];
        this.peerSlugs[i] = this.DB[i][this.slug_index];
        this.categories[i] = this.DB[i][this.category_index];
        this.names[i] = this.DB[i][this.name_index];
      }
      this.clusters = this.clusters.filter((el, i, a) => i === a.indexOf(el))
      for (let j in this.clusters) {
        this.peers[this.clusters[j]] = []
        for (let k in this.DB) {
          if(this.clusters[j] == this.DB[k][this.cluster_index]){
            this.peers[this.clusters[j]].push(this.DB[k]); 
          }
        }
      }
      this.getIndexData(this.statsList[0]);
      this.getData(this.statsList[0]);
    }); 
  });
}

statChange(value: any): void {
  this.getIndexData(value);
  this.getData(value);
}

applyFilter(filterValue: string) {
  this.dataSource.filter = filterValue.trim().toLowerCase();
}
applyIndexFilter(filterValue: string) {
  this.indexDataSource.filter = filterValue.trim().toLowerCase();
}

getData(stat){
  this.doCall.Post(environment.EP_Classifications, {}).subscribe(
    result => {this.classification = result[0];
    let url = environment.EP_PeerStats;
    this.stat = stat;
    let options =  {"slugs":this.peerSlugs,"stat":stat};
    this.doCall.Post(url, options).subscribe(res => {
      this.displayedColumns = res["columns"];
      this.obj = res["data"];

      let values = []
      for (let i in this.obj) {
        for (let key in this.obj[i]) {
          if(key!="Peer group")
            values.push(this.obj[i][key]);
        }
      }
      this.lowTreshold = Math.min(...values);
      this.highTreshold = Math.max(...values);
      this.meanTreshold = eval(values.join('+'))/values.length;
      if(stat == "PeriodReturn" || stat=="AvgReturnsAnnual" || stat=="SharpeRatio"){
        this.meanTreshold = 0;
      }
      
      for( let y in this.obj ){
        this.info[this.obj[y]["Peer group"]] = {};
        this.info[this.obj[y]["Peer group"]]["slug"] =  this.peerSlugs[y];
        this.info[this.obj[y]["Peer group"]]["category"] =  this.categories[y];
        this.info[this.obj[y]["Peer group"]]["name"] =  this.names[y];
      }

      this.slugs = res["slugs"];
      this.ELEMENT_DATA2 = this.obj;
      this.dataSource = new MatTableDataSource( this.ELEMENT_DATA2 );
      this.dataSource.sort = this.sortpeers;
    });
  });
}

getIndexData(stat){
  let url = environment.EP_MarketsStats;
  this.stat = stat;
  let options =  {"stat":stat};
  this.doCall.Post(url, options).subscribe(
    result => {
      this.displayedIndexColumns = result["columns"];
      this.indexData = result["data"];
      this.indexSlugs = result["slugs"];
      this.indexNames = result["names"];
      
      for( let z in this.indexData ){
        this.indexInfo[this.indexData[z]["Index"]] = {};
        this.indexInfo[this.indexData[z]["Index"]]["slug"] =  this.indexSlugs[z];
        this.indexInfo[this.indexData[z]["Index"]]["name"] =  this.indexNames[z];
      } 

      this.ELEMENT_INDEX_DATA2 = this.indexData;
      this.indexDataSource = new MatTableDataSource( this.ELEMENT_INDEX_DATA2 );
      this.indexDataSource.sort = this.sortindexes;
  });
}

  openPeerReport(slug,classification,category,name): void {
    let dialogRef = this.dialog.open(BlockViewpeerPopupComponent, {
      width: '1700px',
      height: '800px',
      maxWidth: '98%',
      minWidth: '95%',
      maxHeight: '98%',
      minHeight: '95%'
    });
    let instance = dialogRef.componentInstance;
    instance.slug = slug;
    instance.classification = classification;
    instance.category = category;
    instance.name = name;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openIndexReport(slug,name): void {
    let dialogRef = this.dialog.open(BlockViewitemPopupComponent, {
      width: '1700px',
      height: '800px',
      maxWidth: '98%',
      minWidth: '95%',
      maxHeight: '98%',
      minHeight: '95%'
    });
    let instance = dialogRef.componentInstance;
    instance.slug = slug;
    instance.name = name;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  excelExportOnClick() {
    this.excelExport.exportAsExcelFile(this.obj,"data");
   };

   excelIndexExportOnClick() {
    this.excelExport.exportAsExcelFile(this.indexData,"data");
   };
}


