import { Component, OnInit, Input } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { environment } from '../../../environments/environment';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';

@Component({
  selector: 'app-module-balance',
  templateUrl: './module-balance.component.html',
  styleUrls: ['./module-balance.component.scss']
})
export class ModuleBalanceComponent implements OnInit {
  @Input() tab;
  selectedTab: any;
  selectedFreqTab: any;
  objTab: any;
  rebalance: any;
  rebalancefreqs: any;
  rebalanceInfo: any;
  rebalancefreqsInfo: any;
  constructor(
    private WalletManager: ServiceWalletManagerService,
    private doCall: ServiceDoCallService
    ) {
      
  }

  TakeSelectTab(event: MatTabChangeEvent) {
    let storage: any = this.WalletManager.GetWallet();
    this.objTab[this.tab].settings.rebalanceOptions.rebalanceMode = this.rebalance[event.index].value;
    storage.ptfs = this.objTab;
    this.WalletManager.SetWallet(storage);
    this.selectedTab = event.index;
    this.rebalanceInfo = this.rebalance[event.index].info;
  }

  TakeSelectFreqTab(event: MatTabChangeEvent) {
    let storage: any = this.WalletManager.GetWallet();
    this.objTab[this.tab].settings.rebalanceOptions.frequence = this.rebalancefreqs[event.index].value;
    storage.ptfs = this.objTab;
    this.WalletManager.SetWallet(storage);
    this.selectedFreqTab = event.index;
    this.rebalancefreqsInfo = this.rebalancefreqs[event.index].info;
  }

  ngOnInit() {
    let storage: any = this.WalletManager.GetWallet();
    this.objTab =storage.ptfs;
    if(this.objTab[this.tab].settings.rebalanceOptions.frequence == undefined)
      this.objTab[this.tab].settings.rebalanceOptions.frequence = "B";
    storage.ptfs = this.objTab;
    this.WalletManager.SetWallet(storage);
    this.doCall.Get(environment.EP_Rebalance,{}).subscribe(res => {
      this.rebalance = res;
      this.rebalance.forEach((element, index) => {
        if(this.objTab[this.tab].settings.rebalanceOptions.rebalanceMode === element.value){
          this.selectedTab = index;
          this.rebalanceInfo = element.info
        }
      }); 
    });
    this.doCall.Get(environment.EP_Frequence,{}).subscribe(res => {
      this.rebalancefreqs = res;
      this.rebalancefreqs.forEach((element, index) => {
        if(this.objTab[this.tab].settings.rebalanceOptions.frequence === element.value){
          this.selectedFreqTab = index;
          this.rebalancefreqsInfo = element.info
        }
      }); 
    });
  }
}