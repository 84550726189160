import { Component, OnInit, Input} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../store';
import { COOKIE_SET } from '../../actions';
import { BlockSettingsToolComponent } from '../../blocks/block-settings-tool/block-settings-tool.component';
import { environment } from '../../../environments/environment';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';

@Component({
  selector: 'app-module-weight',
  templateUrl: './module-weight.component.html',
  styleUrls: ['./module-weight.component.scss']
})

export class ModuleWeightComponent implements OnInit {
  @Input() tab;
  @Input() disalbedInSettings;
  objTab: any;
  storage : any;
  selectedTab: any;
  weight: any;
  optimizations: any;
  selectedOpt: any;
  tails: any;
  selectedTail: any;
  weightInfo: any;
  optInfo: any;
  tailInfo: any;
  constructor(
    private ngRedux: NgRedux<IAppState>, 
    private WalletManager: ServiceWalletManagerService,
    private doCall: ServiceDoCallService,
    private parent: BlockSettingsToolComponent) {
      this.storage = this.WalletManager.GetWallet();
      this.objTab = this.storage.ptfs;
     
  }

  SaveStatus(){
    this.ngRedux.dispatch({
      type: COOKIE_SET, 
      cookie: {
        'WalletNAme': this.storage
      }
    });
  }

  OnChangeVol(event){
    this.storage = this.WalletManager.GetWallet();
    let volConstraint = event.target.value;
    this.objTab[this.tab].settings.weightsOptions.volConstraint = volConstraint;
    this.storage.ptfs = this.objTab;
    this.WalletManager.SetWallet(this.storage);
  }

  TakeSelectTab(event: MatTabChangeEvent) {
    this.storage = this.WalletManager.GetWallet();
    this.objTab = this.storage.ptfs;
    
    this.objTab[this.tab].settings.weightsOptions.weightsMode = this.weight[event.index].value;
    if(event.tab.textLabel == 'optimize'){
      this.objTab[this.tab].settings.weightsOptions.objFunction = this.optimizations[0].value;
    }
    if(event.tab.textLabel == 'optimize' || event.tab.textLabel == 'manual risks' || event.tab.textLabel == 'equal risks'){
      this.objTab[this.tab].settings.weightsOptions.sampleTail = this.tails[0].value;
    }
    this.storage.ptfs = this.objTab;
    this.weightInfo = this.weight[event.index].info;
    this.WalletManager.SetWallet(this.storage);
    this.SaveStatus();
  }

  TakeSelectOpt(event: MatTabChangeEvent) {
    this.storage = this.WalletManager.GetWallet();
    this.objTab = this.storage.ptfs;
    this.objTab[this.tab].settings.weightsOptions.objFunction = this.optimizations[event.index].value;
    this.storage.ptfs = this.objTab;
    this.optInfo = this.optimizations[event.index].info;
    this.WalletManager.SetWallet(this.storage);
  }

  TakeSelectTail(event: MatTabChangeEvent) {
    this.storage = this.WalletManager.GetWallet();
    this.objTab = this.storage.ptfs;
    this.objTab[this.tab].settings.weightsOptions.sampleTail = this.tails[event.index].value;
    this.storage.ptfs = this.objTab;
    this.WalletManager.SetWallet(this.storage);
    this.tailInfo = this.tails[event.index].info;
    this.SaveStatus();
  }

  ngOnInit() {
    this.doCall.Get(environment.EP_Weights,{}).subscribe(res => {
      this.weight = res;
      this.weight.forEach((element, index) => {
          if(this.objTab[this.tab].settings.weightsOptions.weightsMode === element.value){
            this.selectedTab = index;
            this.weightInfo = element.info;
          }
      });
    });
    this.doCall.Get(environment.EP_Optimizations,{}).subscribe(result => {
      this.optimizations = result;
      this.optimizations.forEach((el, ind) => {
        if(this.objTab[this.tab].settings.weightsOptions.objFunction === el.value){
          this.selectedOpt = ind;
          this.optInfo = el.info;
        }
      });
    });
    this.doCall.Get(environment.EP_Tails,{}).subscribe(re => {
      this.tails = re;
      this.tails.forEach((ele, indx) => {
        if(this.objTab[this.tab].settings.weightsOptions.sampleTail === ele.value){
          this.selectedTail = indx;
          this.tailInfo = ele.info;
        }
      });
    });
  }

}
