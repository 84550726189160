

export const COOKIE_SET = 'COOKIE_SET';
export const STORAGE_ID = 'STORAGE_ID';
export const CHANGE_COMPONENT = 'CHANGE_COMPONENT';
export const ENVSTATUS = 'ENVSTATUS';
export const ADD_TODO = 'ADD_TODO';
export const TOGGLE_TODO = 'TOGGLE_TODO';
export const REMOVE_TODO = 'REMOVE_TODO';
export const REMOVE_ALL_TODOS = 'REMOVE_ALL_TODOS';

