import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTabChangeEvent } from '@angular/material';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../store';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ServiceWalletSaveService } from '../../services/service-wallet-save.service';

@Component({
  selector: 'app-block-simulate-popup',
  templateUrl: './block-simulate-popup.component.html',
  styleUrls: ['./block-simulate-popup.component.scss']
})
export class BlockSimulatePopupComponent implements OnInit {
  refIndex:any;
  types:any;
  morncats:any;
  peers:any;
  isinsortickers:any;
  infocurrencies:any;
  names:any;
  frequences:any;
  items:any;
  freqsLabels:any;
  storage:any;
  scenarios:any;
  volatilities:any;
  selectedScenario:any;
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private WalletManager: ServiceWalletManagerService,
    private SaveWallet: ServiceWalletSaveService,
    public doCall: ServiceDoCallService,
    public router: Router,
    public dialogRef: MatDialogRef<BlockSimulatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.refIndex = this.data.refIndex;
    this.freqsLabels = {"B":"daily","W-FRI":"weekly","SM":"bi-weekly","BM":"monthly","BQ":"quarterly","BA":"yearly"};

    this.names = {};
    this.frequences = {};
    this.morncats = {};
    this.types = {};
    this.peers = {};
    this.isinsortickers = {};
    this.infocurrencies = {};
    this.volatilities={};
    this.selectedScenario = "lastYear";

   }

   onExpRetChange(expRetValue : string, index ) {  
    this.storage = this.WalletManager.GetWallet();
    this.storage.ptfs[this.refIndex].items[index].expRet = expRetValue;
    this.WalletManager.SetWallet(this.storage);
  }

  onVolFactChange(volFactValue : string ) {  
    this.storage = this.WalletManager.GetWallet();
    this.storage.ptfs[this.refIndex].settings.simOptions.volFactor = volFactValue;
    this.WalletManager.SetWallet(this.storage);
  }

  onSimNumberChange(simNumberValue : string ) {  
    this.storage = this.WalletManager.GetWallet();
    this.storage.ptfs[this.refIndex].settings.simOptions.simNumber = simNumberValue;
    this.WalletManager.SetWallet(this.storage);
  }

  onSimYearsNumberChange(simYearsNumberValue : string ) {  
    this.storage = this.WalletManager.GetWallet();
    this.storage.ptfs[this.refIndex].settings.simOptions.simYearsNumber = simYearsNumberValue;
    this.WalletManager.SetWallet(this.storage);
  }

  onSharpeFactChange(sharpeFactValue : string ) {  
    this.storage = this.WalletManager.GetWallet();
    this.storage.ptfs[this.refIndex].settings.simOptions.sharpeFactor = sharpeFactValue;
    this.WalletManager.SetWallet(this.storage);
  }

   GetItemsInfo(){
    this.items.forEach((element) => {
      this.doCall.Post(environment.EP_ItemAnagraphics,{"slugs":[element.slug]}).subscribe(result => {
        this.types[element.slug] = result[0][0];
        this.morncats[element.slug] = result[0][1];
        this.peers[element.slug] = result[0][2];
        this.isinsortickers[element.slug] = result[0][3];
        this.infocurrencies[element.slug] = result[0][4];
        this.names[element.slug] = result[0][5];
        this.frequences[element.slug] = this.freqsLabels[result[0][6]];
      });
    });
  }

  GetItemsVol(){
    this.items.forEach((element) => {
      this.doCall.Post(environment.EP_ItemVol,{"slug":element.slug,"scenario":this.selectedScenario}).subscribe(result => {
        this.volatilities[element.slug] = result;
      });
    });
  }

  simulatePtf(){
    this.SaveWallet.CacheSingleSimPortfolio(this.refIndex);
  }

  ngOnInit() {
    this.storage = this.WalletManager.GetWallet();

    this.doCall.Get(environment.EP_Scenarios,{}).subscribe(result => {
      this.scenarios = result;
    });
    this.items = this.storage["ptfs"][this.refIndex].items;
    if(this.storage.ptfs[this.refIndex].settings.simOptions == undefined){
      this.storage.ptfs[this.refIndex].settings.simOptions = {};
      this.storage.ptfs[this.refIndex].settings.simOptions.volFactor = 100;
      this.storage.ptfs[this.refIndex].settings.simOptions.simNumber = 500;
      this.WalletManager.SetWallet(this.storage);
    }

    this.storage.ptfs[this.refIndex].settings.simOptions.simYearsNumber = 3;

    this.storage.ptfs[this.refIndex].settings.simOptions.sharpeFactor = 0.2;

    this.WalletManager.SetWallet(this.storage)

    this.GetItemsInfo();
    this.GetItemsVol();
  }

  TakeSelectScenario(event: MatTabChangeEvent){
    this.storage = this.WalletManager.GetWallet();
    this.storage.ptfs[this.refIndex].settings.simOptions.scenario = this.scenarios[event.index].value;
    this.selectedScenario = this.scenarios[event.index].value;
    this.GetItemsVol();
    this.WalletManager.SetWallet(this.storage);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
