import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MatNativeDateModule } from '@angular/material';
import { MatTabChangeEvent } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceWalletSaveService } from '../../services/service-wallet-save.service';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-block-view-popup',
  templateUrl: './block-view-popup.component.html',
  styleUrls: ['./block-view-popup.component.scss'],
})
export class BlockViewPopupComponent implements OnInit {
  @Input() data;
  objTab: any;
  objSettingsTab: any;
  temp: any;
  gr: any;
  start: any;
  end: any;
  date:any;
  path: any;
  xx: any;
  availableStart: any;
  availableEnd: any;
  rebalanceMode: any;
  availableYears:any;
  constructor(
    public dialogRef: MatDialogRef<BlockViewPopupComponent>,
    private doCall: ServiceDoCallService,
    private WalletManager: ServiceWalletManagerService,
    public datepipe: DatePipe,
    public router: Router,
    public routing: ActivatedRoute,
    public cache: ServiceWalletSaveService
  ) { 
    this.objTab = [];
  }
  
  ngOnInit() {
    this.doCall.Get(environment.EP_Frequence,{})
    .subscribe(res => {
        this.objSettingsTab = res;
    });
    this.GetTimeFrames();

    this.gr = "daily";
    let storage : any = this.WalletManager.GetWallet();
    if(storage.ptfs[this.data.index].options == undefined)
      storage.ptfs[this.data.index].options = {}
    storage.ptfs[this.data.index].options.frequence =  "B";
    storage.ptfs[this.data.index].options.tail =  "";
    this.WalletManager.SetWallet(storage);
  }

  StartDateChange(event){
    this.date=new Date(event.value);
    this.start = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    let storage : any = this.WalletManager.GetWallet();
    if(!storage.ptfs[this.data.index].options){
      storage.ptfs[this.data.index].options = {};
      storage.ptfs[this.data.index].options.startDate = this.start;
    }else{
      storage.ptfs[this.data.index].options.startDate = this.start;
    }
    this.WalletManager.SetWallet(storage);
  }
  EndDateChange(event){
    this.date = new Date(event.value);
    this.end =this.datepipe.transform(this.date, 'yyyy-MM-dd');
    let storage : any = this.WalletManager.GetWallet();
    if(!storage.ptfs[this.data.index].options){
      storage.ptfs[this.data.index].options = {};
      storage.ptfs[this.data.index].options.endDate = this.end;
    }else{
      storage.ptfs[this.data.index].options.endDate = this.end;
    }
    this.WalletManager.SetWallet(storage);
  }

  SetMaxStartDate(startDate){
    let storage : any = this.WalletManager.GetWallet();
    this.date=new Date(startDate);
    this.start = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    if(!storage.ptfs[this.data.index].options){
        storage.ptfs[this.data.index].options = {};
        storage.ptfs[this.data.index].options.startDate = this.start;
    }else{
        storage.ptfs[this.data.index].options.startDate = this.start;
      }
    this.WalletManager.SetWallet(storage);
  }

  TabClick(event: MatTabChangeEvent, context) {
    let storage : any = this.WalletManager.GetWallet();
    //tabs orizzonte temporale
    if(context == 'temp'){
      this.temp = this.objTab[event.index].value;
      if(this.objTab[event.index].label == "Max"){
        this.SetMaxStartDate(this.availableStart);
        let today = new Date();
        storage.ptfs[this.data.index].options.endDate = this.datepipe.transform(today, 'yyyy-MM-dd');
      }
      else{
        if(!storage.ptfs[this.data.index].options){
          storage.ptfs[this.data.index].options = {};
          storage.ptfs[this.data.index].options.tail = this.temp;
        }else{
          storage.ptfs[this.data.index].options.tail = this.temp;
        }
      }
      this.WalletManager.SetWallet(storage);
    }

    this.rebalanceMode =  storage.ptfs[this.data.index].settings.rebalanceOptions.rebalanceMode;
    this.gr = this.objSettingsTab[0]['value'];

    //tabs granularità
    if(context == 'gr'){
      this.gr = this.objSettingsTab[event.index]['value'];
      if(this.gr =='undefined')
        this.gr = "daily"
      if(!storage.ptfs[this.data.index].options){
        storage.ptfs[this.data.index].options = {};
        storage.ptfs[this.data.index].options.frequence = this.gr;
      }else{
         storage.ptfs[this.data.index].options.frequence = this.gr;
      }
      this.WalletManager.SetWallet(storage);
    }
  }

  ViewWallet(){
    
    let storage : any = this.WalletManager.GetWallet();
    let startdate = storage.ptfs[this.data.index].options.startDate;
    let endate = storage.ptfs[this.data.index].options.endDate;
    if(storage.ptfs[this.data.index].options.tail == '' && ( startdate == '' || endate == '')){
      alert('insert date');
    }else{
      this.cache.CacheSinglePortfolio(this.data.index,true);
      
    }  
  }

  GetTimeFrames(){
    let storage : any = this.WalletManager.GetWallet();
    let slugs = [];
    storage.ptfs[this.data.index].items.forEach((element) => {
      slugs.push(element.slug);
    });
    let useProxy = false
    if(storage.ptfs[this.data.index].options.useProxy == true)
      useProxy = true

    this.doCall.Post(environment.EP_TimeFrame,{
      "slugs":slugs,"weightsOptions":storage.ptfs[this.data.index].settings.weightsOptions,"useProxy":useProxy}).subscribe(result => {
      this.availableStart = result[0];
      this.availableEnd = result[1];
      let start = new Date(this.availableStart);
      let end = new Date(this.availableEnd);
      var diff = Math.abs(start.getTime() - end.getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      this.availableYears = Math.round(diffDays/365*10)/10

      this.doCall.Get(environment.EP_Thorizon,{}).subscribe(res => {
        this.objTab = [];
        let result: any;
        result = res;
        result.forEach(element => {
          if(element.label == "Max" || element.value <  this.availableYears){
            this.objTab.push(element);
          }   
        });
        this.SetMaxStartDate(this.availableStart);
      });
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
