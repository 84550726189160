import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { HttpModule } from '@angular/http';
import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { IAppState, rootReducer, INITIAL_STATE } from './store';
import {MatTabsModule} from '@angular/material/tabs';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { WebStorageModule } from 'ngx-store';
import { LocalStorageModule } from 'angular-2-local-storage';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatGridListModule} from '@angular/material/grid-list';
import { jqxChartComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { GestureConfig } from '@angular/material';
import jwt_decode from 'jwt-decode';

import { 
  MatToolbarModule, 
  MatButtonModule, 
  MatSidenavModule, 
  MatIconModule, 
  MatListModule, 
  MatCardModule,
  MatInputModule,
  MatSliderModule,
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  MAT_NATIVE_DATE_FORMATS,
  MatDateFormats,
  MatMenuModule,
  MatSortModule,
  MatTableModule,
  MatPaginator,
  _MatPaginatorBase,
  MatPaginatorModule,
  MAT_DATE_FORMATS
  } 
from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { ModuleNavComponent } from './modules/module-nav/module-nav.component';
import { ModuleAddWalletComponent } from './modules/module-add-wallet/module-add-wallet.component';
import { BlockAddWalletPopupComponent } from './blocks/block-add-wallet-popup/block-add-wallet-popup.component';
import { ModuleTabWalletComponent } from './modules/module-tab-wallet/module-tab-wallet.component';
import { ModuleAddToolsComponent } from './modules/module-add-tools/module-add-tools.component';
import { BlockToolsPopupComponent } from './blocks/block-tools-popup/block-tools-popup.component';
import { BlockShowToolsComponent } from './blocks/block-show-tools/block-show-tools.component';
import { ModuleEditToolsComponent } from './modules/module-edit-tools/module-edit-tools.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BlockSettingsToolComponent } from './blocks/block-settings-tool/block-settings-tool.component';
import { ModuleWeightComponent } from './modules/module-weight/module-weight.component';
import { ModulePickingComponent } from './modules/module-picking/module-picking.component';
import { ModuleBalanceComponent } from './modules/module-balance/module-balance.component';
import { ModuleCostComponent } from './modules/module-cost/module-cost.component';
import { ModuleProxyComponent } from './modules/module-proxy/module-proxy.component';
import { ModuleSelectCurrencyComponent } from './modules/module-select-currency/module-select-currency.component';
import { BlockViewPopupComponent } from './blocks/block-view-popup/block-view-popup.component';
import { DatePipe } from '../../node_modules/@angular/common';
import { RouterModule } from '../../node_modules/@angular/router';
import { ModuleOutputComponent } from './modules/module-output/module-output.component';
import { routingModule } from './app.routing';
import { ModuleNavEditComponent } from './modules/module-nav-edit/module-nav-edit.component';
import { jqxBarGaugeComponent } from '../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxbargauge';
import { ChartStackedareaseriesComponent } from './charts/chart-stackedareaseries/chart-stackedareaseries.component';
import { ChartAreasplineseriesComponent } from './charts/chart-areasplineseries/chart-areasplineseries.component';
import { ChartTableComponent } from './charts/chart-table/chart-table.component';
import { ChartListComponent } from './charts/chart-list/chart-list.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ChartDonutComponent } from './charts/chart-donut/chart-donut.component';
import { ModuleLoginComponent } from './modules/module-login/module-login.component';
import { ModuleHomeComponent } from './modules/module-home/module-home.component';
import { ModuleMyAreaComponent } from './modules/module-my-area/module-my-area.component';
import { ChartBarsComponent } from './charts/chart-bars/chart-bars.component';
import { ChartSpiderComponent } from './charts/chart-spider/chart-spider.component';
import { ChartWaterfallComponent } from './charts/chart-waterfall/chart-waterfall.component';
import { ChartTableCorrelationsComponent } from './charts/chart-table-correlations/chart-table-correlations.component';
import { ModuleDbComponent } from './modules/module-db/module-db.component';
import { BlockTablePopupComponent } from './blocks/block-table-popup/block-table-popup.component';
import { BlockComparePopupComponent } from './blocks/block-compare-popup/block-compare-popup.component';
import { BlockSimulatePopupComponent } from './blocks/block-simulate-popup/block-simulate-popup.component';
import { BlockViewitemPopupComponent } from './blocks/block-viewitem-popup/block-viewitem-popup.component';
import { ChartScatterComponent } from './charts/chart-scatter/chart-scatter.component';
import {ServiceExcelExportService} from './services/service-excel-export.service';
import { BlockViewpeerPopupComponent } from './blocks/block-viewpeer-popup/block-viewpeer-popup.component';
import { ModuleMarketsComponent } from './modules/module-markets/module-markets.component';

//create our cost var with the information about the format that we want
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    jqxChartComponent,
    jqxBarGaugeComponent,
    AppComponent,
    ModuleNavComponent,
    ModuleAddWalletComponent,
    BlockAddWalletPopupComponent,
    ModuleTabWalletComponent,
    ModuleAddToolsComponent,
    BlockToolsPopupComponent,
    BlockShowToolsComponent,
    ModuleEditToolsComponent,
    BlockSettingsToolComponent,
    ModuleWeightComponent,
    ModulePickingComponent,
    ModuleBalanceComponent,
    ModuleCostComponent,
    ModuleProxyComponent,
    ModuleSelectCurrencyComponent,
    BlockViewPopupComponent,
    ModuleOutputComponent,
    ModuleNavEditComponent,
    ChartStackedareaseriesComponent,
    ChartAreasplineseriesComponent,
    ChartTableComponent,
    ChartListComponent,
    ChartDonutComponent,
    ModuleLoginComponent,
    ModuleHomeComponent,
    ModuleMyAreaComponent,
    ChartBarsComponent,
    ChartSpiderComponent,
    ChartWaterfallComponent,
    ChartTableCorrelationsComponent,
    ModuleDbComponent,
    BlockTablePopupComponent,
    BlockComparePopupComponent,
    BlockSimulatePopupComponent,
    BlockViewitemPopupComponent,
    ChartScatterComponent,
    BlockViewpeerPopupComponent,
    ModuleMarketsComponent
  ],
  imports: [
    MatMenuModule,
    routingModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatBottomSheetModule,
    FormsModule,
    MatInputModule,
    MatSliderModule,
    HttpModule,
    NgReduxModule,
    MatTabsModule,
    WebStorageModule,
    MatDialogModule,
    MatCheckboxModule,
    MatChipsModule,
    MatExpansionModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatGridListModule,
    NgHttpLoaderModule,
    MatAutocompleteModule,
    LocalStorageModule.withConfig({
      prefix: '',
      storageType: 'localStorage'
  })
  ],
  exports: [],
  entryComponents: [BlockAddWalletPopupComponent, BlockToolsPopupComponent, BlockViewPopupComponent, ModuleDbComponent, BlockTablePopupComponent, BlockComparePopupComponent, BlockSimulatePopupComponent, BlockViewitemPopupComponent, BlockViewpeerPopupComponent],
  providers: [
    CookieService,
    MatNativeDateModule,
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'it' }, //you can change useValue
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor (ngRedux: NgRedux<IAppState>) {
      ngRedux.configureStore(rootReducer, INITIAL_STATE);
  }
}
