import { Component, OnInit, Input, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../store';
import { ServiceWalletSaveService } from '../../services/service-wallet-save.service';
import { ServiceAuthService } from '../../services/service-auth.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { COOKIE_SET } from '../../actions';
import { AlertPromise } from 'selenium-webdriver';

@Component({
  selector: 'app-module-tab-wallet',
  templateUrl: './module-tab-wallet.component.html',
  styleUrls: ['./module-tab-wallet.component.scss'],
  encapsulation: ViewEncapsulation.None
})  

export class ModuleTabWalletComponent implements OnInit {
  objTab: any;
  input: any;
  peso : any;
  WalletCount : any;
  ButtonVisible : any;
  constructor(
    public cookieService: CookieService,
    private ngRedux: NgRedux<IAppState>, 
    private WalletManager: ServiceWalletManagerService,
    private saveService: ServiceWalletSaveService,
    public auth: ServiceAuthService,
    private Login: ServiceAuthService
    ) {
      this.objTab = [];
      this.ngRedux.subscribe(
        () => {
          let state = this.ngRedux.getState();
          if(state.lastAction === 'COOKIE_SET' && this.WalletManager.GetWallet() != null){
            let storage : any = this.WalletManager.GetWallet();
            this.objTab = storage.ptfs; 
          }
        }
      );
    }

  RemoveChecked(){
    this.ButtonVisible = false;
  }

  RemovePtfTab(ptfIndex){
    this.WalletManager.DeletePtf(ptfIndex);
    this.ngRedux.dispatch({
      type: COOKIE_SET, 
      cookie: {
        'WalletNAme': this.WalletManager.GetWallet()
      }
    });
    let storage : any = this.WalletManager.GetWallet();
    this.objTab = storage.ptfs;
  }
  
  SaveWallet(index,name,description=""){
    this.saveService.SaveSinglePortfolio(index,name,description);
  }

  ngOnInit() {
    this.auth.RedirectToLogin();
    this.Login.CheckLogin();
    if(this.WalletManager.GetWallet() != null){
      let storage : any = this.WalletManager.GetWallet();
      this.objTab = storage.ptfs;
    }
  }

}