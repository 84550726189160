import { Component, OnInit } from '@angular/core';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { ServiceAuthService } from '../../services/service-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-module-home',
  templateUrl: './module-home.component.html',
  styleUrls: ['./module-home.component.scss']
})
export class ModuleHomeComponent implements OnInit {
  myPtfs: any;
  constructor(
    public auth: ServiceAuthService,
    public router: Router,
    ) { 
    }

  ngOnInit() {
    this.auth.RedirectToLogin();
  }

  GoToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  GoToMyArea() {
    this.router.navigate(['/myarea']);
  }

  GoToDB() {
    this.router.navigate(['/database']);
  }

  GoToMarkets() {
    this.router.navigate(['/markets']);
  }

}