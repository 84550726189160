import { Component, OnInit } from '@angular/core';
import {MatBottomSheet} from '@angular/material';
import { BlockAddWalletPopupComponent } from '../../blocks/block-add-wallet-popup/block-add-wallet-popup.component';
import {FormControl} from '@angular/forms';
import {TooltipPosition} from '@angular/material';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';

@Component({
  selector: 'app-module-add-wallet',
  templateUrl: './module-add-wallet.component.html',
  styleUrls: ['./module-add-wallet.component.scss'],
  
})
export class ModuleAddWalletComponent implements OnInit {
  constructor(
    private bottomSheet: MatBottomSheet,
    private WalletManager: ServiceWalletManagerService
    ) {}
  
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new FormControl(this.positionOptions[5]);
  objTab: any;

  openBottomSheet(): void {
    this.bottomSheet.open(BlockAddWalletPopupComponent);
  }

  ngOnInit() {
    let storage : any = this.WalletManager.GetWallet();
    if(storage == undefined){
      this.openBottomSheet();
    }
    else if(storage.ptfs.length == 0){
      this.openBottomSheet();
    }
  }

}






