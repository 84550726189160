import { Component, OnInit, Input, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { jqxChartComponent } from '../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { environment } from '../../../environments/environment';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { ServiceExcelExportService } from '../../services/service-excel-export.service';

@Component({
  selector: 'app-chart-donut',
  templateUrl: './chart-donut.component.html',
  styleUrls: ['./chart-donut.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChartDonutComponent implements OnInit {
  @Input() endpoint;
  @Input() indexWallet;
  data: any;
  option: any;
  height:any;
  classification:any;
  seriesGroups: any = [];
  @ViewChild('myChart')myChart: jqxChartComponent;

  constructor(
    private WalletManager: ServiceWalletManagerService,
    private doCall: ServiceDoCallService,
    private excelExport: ServiceExcelExportService
  ) { 
    this.seriesGroups[0] = {};
  }

  getData(level=0){
    let storage:any = this.WalletManager.GetWallet(); 
    let url = this.endpoint;
    let id = storage.ptfs[this.indexWallet].id;
    this.doCall.Post(environment.EP_Classifications, {}).subscribe(
        result => {this.classification = result[0];
        this.option =  {"id": id,"classification":this.classification,"level":level};
        this.doCall.Post(url, this.option).subscribe(res => {
          let mydata = {};
          let Arr = [];
          for (let key in res) {
            let value = res[key];
            mydata = {
              label : key,
              value : res[key]
            }
            Arr.push(mydata);
            this.data = Arr;
          }
          if(this.data.length>10){
          this.data.sort(function(obj1, obj2) {
            return obj2.value - obj1.value;
          })
          this.height = 55*(Arr.length);
          this.seriesGroups[0] =
            {
              "type": 'column',
              "orientation": 'horizontal',
              "showLabels": true,
              "showLegend": false,
              "useGradient": false,
              "valueAxis":{
                gridLines: { visible: false},
                tickMarks: { visible: false},
                labels:{visible: false},
                flip:true
              },
              "xAxis" : {
                dataField: 'label',
                gridLines: {
                  visible: false 
                  },
                labels: {
                  angle: 90 ,
                  horizontalAlignment:'right',
                  formatFunction : function(str){
                    let newString = "";
                    let l=35;
                    while(str.length > l){
                        var pos = str.substring(0, l).lastIndexOf(' ');
                        pos = pos <= 0 ? l : pos;
                        newString = newString + "<br>" + (str.substring(0, pos));
                        var i = str.indexOf(' ', pos)+1;
                        if(i < pos || i > pos+l)
                            i = pos;
                        str = str.substring(i);
                    }
                    newString = newString + "<br>" + str;
                    return newString;
                  }
                }
              },
              "series":
              [{
                dataField: 'value',
                color:"#02BAC591", 
                formatSettings: { sufix: '%', decimalPlaces: 2}
              }]
            }
          }
          else{
            this.height = 500;
            this.seriesGroups[0] =
            {
              "type":"donut",
              "showLabels": true,
              "useGradient": false,
              "series":
              [{
                dataField: 'value',
                displayText: 'label',
                labelRadius: 120,
                initialAngle: 15,
                radius: 170,
                innerRadius: 70,
                centerOffset: 0,
                formatSettings: { sufix: '%', decimalPlaces: 1 },
                legendPosition: { right: 0, top: 140, width: 100, height: 100 },
                padding: { left: 5, top: 25, right: 5, bottom: 5 },
                titlePadding: { left: 0, top: 0, right: 0, bottom: 10 }
              }]
            }
          }
        });
    });
  }

  ngOnInit() {
    if(this.endpoint){
      this.getData(2);
    }
    let data = this.myChart;
    let timer = setTimeout(() => {
      this.myChart.update();
    }, 1);
  }

  pngExportOnClick() {
    function getExportServer() {
      return 'http://www.jquerygrid.net/export_server/export.php';
    }
    this.myChart.saveAsPNG("donutchart.png",getExportServer());
   };

   excelExportOnClick() {
    this.excelExport.exportAsExcelFile(this.data,"data");
   };

}
