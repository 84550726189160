import {Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource, MatDialog} from '@angular/material';
import { environment } from '../../../environments/environment';
import { IAppState } from '../../store';
import { NgRedux } from '@angular-redux/store';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { BlockViewitemPopupComponent } from '../../blocks/block-viewitem-popup/block-viewitem-popup.component';
import { ServiceExcelExportService } from '../../services/service-excel-export.service';
import { BlockTablePopupComponent } from '../../blocks/block-table-popup/block-table-popup.component';

export interface PeriodicElement {
  year: string,
  Jan: number,
  Feb: number,
  Mar: number,
  Apr: number,
  May: number,
  Jun: number,
  Jul: number,
  Aug: number,
  Sep: number,
  Oct: number,
  Nov: number,
  Dec: number
}

@Component({
  selector: 'app-chart-table',
  templateUrl: './chart-table.component.html',
  styleUrls: ['./chart-table.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ChartTableComponent implements OnInit {
  @Input() endpoint;
  @Input() indexWallet;
  @Input() label;
  @Input() id;
  @Input() slug;
  @Input() compId;
  @Input() category;
  @Input() singleRow;
  @Input() tabledata;
  //displayedColumns: string[] = ["year", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug","Sep","Oct","Nov","Dec"];
  displayedColumns: string[];
  obj :any;
  dataSource: any;
  classification: any;
  simId: any;
  slugs: any;
  codes: any;
  categoryFunds: any;
  fundsNames: any;
  modelArray: any;
  ELEMENT_DATA2: PeriodicElement[];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private doCall: ServiceDoCallService,
    private WalletManager: ServiceWalletManagerService,
    public dialog: MatDialog,
    private excelExport: ServiceExcelExportService,
    private ngRedux: NgRedux<IAppState>
    ) {
      //dati per il primo atterraggio
      this.ngRedux.subscribe(() => {
        let state = this.ngRedux.getState();
        if(state.lastAction === 'STORAGE_ID'){
          this.getData();
        }
      });
      if(this.singleRow == undefined){
        this.singleRow = false;
      }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getData(){
    let storage:any = this.WalletManager.GetWallet();
    if(!this.id){
      this.id = storage.ptfs[this.indexWallet].id;
    }
    if(storage != undefined){
      if(storage.ptfs != undefined){
        if(storage.ptfs[this.indexWallet] != undefined)
        this.simId = storage.ptfs[this.indexWallet].simId;
      }
    }
    this.doCall.Post(environment.EP_Classifications, {}).subscribe(
      result => {this.classification = result[0];
      let url = this.endpoint;
      let options =  {"id": this.id,"compId":this.compId,"simId":this.simId,"classification":this.classification,"category":this.category,"slug":this.slug};
      this.doCall.Post(url, options).subscribe(res => {
        this.displayedColumns = res["columns"];
        this.obj = res["data"];
        this.slugs = res["slugs"];
        this.codes = res["codes"];
        this.categoryFunds = res["categoryFunds"];
        this.fundsNames = res["fundsNames"];
        this.ELEMENT_DATA2 = this.obj;
        this.dataSource = new MatTableDataSource( this.ELEMENT_DATA2 );
        this.dataSource.sort = this.sort;
      });
  });
  }

  useData(){
      this.displayedColumns = this.tabledata["columns"];
      this.obj = this.tabledata["data"];
      this.slugs = this.tabledata["slugs"];
      this.codes = this.tabledata["codes"];
      this.categoryFunds = this.tabledata["categoryFunds"];
      this.fundsNames = this.tabledata["fundsNames"];
      this.ELEMENT_DATA2 = this.obj;
      this.dataSource = new MatTableDataSource( this.ELEMENT_DATA2 );
      this.dataSource.sort = this.sort;
  }

  openFundReport(slug,name,code): void {
    let dialogRef = this.dialog.open(BlockViewitemPopupComponent, {
      width: '1700px',
      height: '800px',
      maxWidth: '98%',
      minWidth: '95%',
      maxHeight: '98%',
      minHeight: '95%'
    });
    let instance = dialogRef.componentInstance;
    instance.slug = slug;
    instance.name = name;
    instance.code = code;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openFundsList(yearFunds,year): void {
    let dialogRef = this.dialog.open(BlockTablePopupComponent, {
      width: '800px',
      height: '800px',
      maxWidth: '98%',
      minWidth: '45%',
      maxHeight: '98%',
      minHeight: '45%'
    });
    let instance = dialogRef.componentInstance;
    instance.type = "funds";
    instance.label = this.category + " " + year;
    instance.fundsSlugs = yearFunds;
    instance.fundsNames = this.fundsNames;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnInit() {
    //dati per ogni refresh
    if(this.tabledata){
      this.useData();
    }
    else{
      let storage:any = this.WalletManager.GetWallet();
      if(!this.id)
        this.id = storage.ptfs[this.indexWallet].id;
      if(this.id != ''){
        this.getData();
      }
    }
  }

   excelExportOnClick() {
    this.excelExport.exportAsExcelFile(this.obj,"data");
   };


}
