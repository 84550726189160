import {Component, OnInit, Input, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { ModuleTabWalletComponent } from '../../modules/module-tab-wallet/module-tab-wallet.component';

@Component({
  selector: 'app-block-settings-tool',
  templateUrl: './block-settings-tool.component.html',
  styleUrls: ['./block-settings-tool.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BlockSettingsToolComponent implements OnInit {
  @Input() tab;
  @Input() Changedtools;
  disabled: any;
  disalbedInSettings: any;
  var: any;
  
  constructor(private parent:ModuleTabWalletComponent) {
  }

  



  ngOnInit() {
  }

}
