import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-module-picking',
  templateUrl: './module-picking.component.html',
  styleUrls: ['./module-picking.component.scss']
})
export class ModulePickingComponent implements OnInit {
  SettingsTab = ['Ranking', 'Tutti'];
  @Input() tab;
  constructor() { }

  ngOnInit() {
  }

}
