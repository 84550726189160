import { Component, OnInit, Input } from '@angular/core';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material';
import { BlockTablePopupComponent } from '../../blocks/block-table-popup/block-table-popup.component';
import { ServiceAuthService } from '../../services/service-auth.service';
import { FormControl } from '@angular/forms';
import { BlockViewitemPopupComponent } from '../../blocks/block-viewitem-popup/block-viewitem-popup.component';
import { ActivatedRoute } from '@angular/router';
import { BlockViewpeerPopupComponent } from '../../blocks/block-viewpeer-popup/block-viewpeer-popup.component';

@Component({
  selector: 'app-module-db',
  templateUrl: './module-db.component.html',
  styleUrls: ['./module-db.component.scss']
})
export class ModuleDbComponent implements OnInit {
  @Input() tab; 
  DB: any;
  classification:any;
  clusters:any;
  peers:any;
  cluster_index:any;
  selectedItem:any;
  options: any;
  anagraphics:any;
  freqsLabels:any;
  slugFromGet:any;
  frequences = {};
  inceptions = {};
  lastupdates = {};
  proxyinceptions = {};
  morncats = {};
  types = {};
  itempeers = {};
  selectedPeers = [];
  isinsortickers = {};
  infocurrencies = {};
  names = {};
  
  constructor(
    public doCall: ServiceDoCallService,
    public auth: ServiceAuthService,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.clusters = [];
    this.peers = {};
    this.cluster_index = 3;
    this.frequences = {};
    this.inceptions = {};
    this.lastupdates = {};
    this.proxyinceptions = {};
    this.morncats = {};
    this.names = {};
    this.types = {};
    this.itempeers = {};
    this.isinsortickers = {};
    this.infocurrencies = {};
    this.freqsLabels = {"B":"daily","W-FRI":"weekly","SM":"bi-weekly","BM":"monthly","BQ":"quarterly","BA":"yearly"};
   }

  ngOnInit() {
    this.auth.RedirectToLogin();
    this.activatedRoute.queryParams.subscribe(params => {
      this.slugFromGet = params['slug'];
      if(this.slugFromGet){
        this.doCall.Post(environment.EP_ItemAnagraphics, {"slugs":[this.slugFromGet]}).subscribe(result => {
          this.types[this.slugFromGet] = result[0][0];
          this.morncats[this.slugFromGet] = result[0][1];
          this.itempeers[this.slugFromGet] = result[0][2];
          this.isinsortickers[this.slugFromGet] = result[0][3];
          this.infocurrencies[this.slugFromGet] = result[0][4];
          this.names[this.slugFromGet] = result[0][5];
          this.frequences[this.slugFromGet] = this.freqsLabels[result[0][6]];
          this.selectedPeers = this.itempeers[this.slugFromGet];
          this.selectedItem = this.slugFromGet;
          this.openFundReport(this.slugFromGet);
        });
      }
    });
    this.doCall.Post(environment.EP_Classifications, {}).subscribe(
      res => {this.classification = res[0];
      this.doCall.Get(environment.EP_Categories, {"classification":this.classification,"level":1}).subscribe(
        result => {this.DB = result;
        for (let i in this.DB) {
          this.clusters[i] = this.DB[i][this.cluster_index];
        }
        this.clusters = this.clusters.filter((el, i, a) => i === a.indexOf(el))
        for (let j in this.clusters) {
          this.peers[this.clusters[j]] = []
          for (let k in this.DB) {
            if(this.clusters[j] == this.DB[k][this.cluster_index]){
              this.peers[this.clusters[j]].push(this.DB[k]);
            }
          }
        }
      });
  });
  }
  
  openDialog(title,type): void {
    let dialogRef = this.dialog.open(BlockTablePopupComponent, {
      width: '800px',
      maxWidth: '90%',
    });
    let instance = dialogRef.componentInstance;
    instance.title = title;
    instance.type = type;
    instance.classification = this.classification;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDialogCustomRanking(title,type): void {
    let dialogRef = this.dialog.open(BlockTablePopupComponent, {
      width: '800px',
      maxWidth: '90%',
    });
    let instance = dialogRef.componentInstance;
    instance.title = title;
    instance.type = type;
    instance.classification = this.classification;
    instance.peers = this.peers;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onSearchChange(searchValue:string) {
    let text = searchValue.toLowerCase();
    let params =  {"s": text};
    if(text.length>1)
      this.doCall.Post(environment.EP_Search, params).subscribe(result => {this.options = result;});
    else
    this.selectedItem = "";
  }

  openPeerReport(slug,classification,category,name): void {
    let dialogRef = this.dialog.open(BlockViewpeerPopupComponent, {
      width: '1700px',
      height: '800px',
      maxWidth: '98%',
      minWidth: '95%',
      maxHeight: '98%',
      minHeight: '95%'
    });
    let instance = dialogRef.componentInstance;
    instance.slug = slug;
    instance.classification = classification;
    instance.category = category;
    instance.name = name;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openFundReport(slug): void {
    let dialogRef = this.dialog.open(BlockViewitemPopupComponent, {
      width: '1700px',
      height: '800px',
      maxWidth: '98%',
      minWidth: '95%',
      maxHeight: '98%',
      minHeight: '95%'
    });
    let instance = dialogRef.componentInstance;
    instance.slug = slug;
    instance.name =this.names[slug];
    instance.code =this.isinsortickers[slug];
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openFundReportFromGet(): void {
    let dialogRef = this.dialog.open(BlockViewitemPopupComponent, {
      width: '1700px',
      height: '800px',
      maxWidth: '98%',
      minWidth: '95%',
      maxHeight: '98%',
      minHeight: '95%'
    });
    let instance = dialogRef.componentInstance;
    instance.slug = this.slugFromGet;
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  onSelect(selected:string){
    this.selectedItem = selected;
    this.doCall.Post(environment.EP_ItemAnagraphics, {"slugs":[this.selectedItem]}).subscribe(result => {
      this.types[this.selectedItem] = result[0][0];
      this.morncats[this.selectedItem] = result[0][1];
      this.itempeers[this.selectedItem] = result[0][2];
      this.isinsortickers[this.selectedItem] = result[0][3];
      this.infocurrencies[this.selectedItem] = result[0][4];
      this.names[this.selectedItem] = result[0][5];
      this.frequences[this.selectedItem] = this.freqsLabels[result[0][6]];
      this.selectedPeers = this.itempeers[this.selectedItem];
    });
  }

}
