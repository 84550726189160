import { Component, OnInit, Input } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { BlockToolsPopupComponent } from '../../blocks/block-tools-popup/block-tools-popup.component';
import { BlockViewPopupComponent } from '../../blocks/block-view-popup/block-view-popup.component';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
@Component({
  selector: 'app-module-add-tools',
  templateUrl: './module-add-tools.component.html',
  styleUrls: ['./module-add-tools.component.scss']
})
export class ModuleAddToolsComponent implements OnInit {
  dialogRef: any;
  @Input() tab; 
  @Input() walletname;

  constructor(
    public dialog: MatDialog,
    private WalletManager: ServiceWalletManagerService,
  ) { }
  
  openDialog(): void {
    const dialogRef = this.dialog.open(BlockToolsPopupComponent, {
      width: '600px',
      maxWidth: '90%',
    });
    dialogRef.componentInstance.data= {
      index: this.tab
    };
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  checkInput(){
    let check = true;
    let storage:any = this.WalletManager.GetWallet();
    let total = 0;
    if(storage.ptfs[this.tab].items.length==0){
      check = false;
      alert("Add some items your portfolio");
    }
    if(storage.ptfs[this.tab].settings.weightsOptions.weightsMode == "ManualWeights"){
      total = 0;
      storage.ptfs[this.tab].items.forEach(element => {
        total += parseFloat(element.weight);
        if(element.weight == "" || element.weight == undefined){
          //check = false;
          //alert("Manual Weights are missing!");
        }
      });
    }
    else if(storage.ptfs[this.tab].settings.weightsOptions.weightsMode == "ManualRisks"){
      total = 0;
      storage.ptfs[this.tab].items.forEach(element => {
        total += parseFloat(element.weight);
        if(element.weight == "" || element.weight == undefined){
          //check = false;
          //alert("Manual Risks are missing!");
        
        }
      });
    }
    if(total>100){
      //check = false;
      //alert("Items weights exceed 100%");
    }

    return check;
  }

  openDialogView(): void { 
      let check = this.checkInput();
      if(check == true){
        const dialogRefview = this.dialog.open(BlockViewPopupComponent, {
          maxWidth: '90%',
          width: '900px'
        });
        dialogRefview.componentInstance.data= {
          index: this.tab
        };
        dialogRefview.afterClosed().subscribe(result => {
        });
      }
  }

  ngOnInit() {
  }

}
