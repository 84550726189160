import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { jqxChartComponent } from '../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { environment } from '../../../environments/environment';
import { IAppState } from '../../store';
import { NgRedux } from '@angular-redux/store';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { ServiceExcelExportService } from '../../services/service-excel-export.service';

@Component({
  selector: 'app-chart-stackedareaseries',
  templateUrl: './chart-stackedareaseries.component.html',
  styleUrls: ['./chart-stackedareaseries.component.scss']
})

export class ChartStackedareaseriesComponent implements OnInit {
  @Input() endpoint;
  @Input() type;
  @Input() label;
  @Input() indexWallet;
  @Input() option;
  @Input() data_chart;
  @Input() mode;
  @Input() tresholdIndex;
  @Input() id;
  @Input() compId;
  seriesGroups = [];
  padding: any = { left: 15, top: 35, right: 20, bottom: 5 };
  sampleData: any;
  themeClass: string;
  Isvisible : any;
  key: any;
  reducer: boolean;       
  colors: any;

  @ViewChild('myChart') myChart: jqxChartComponent;

  constructor(
    private doCall: ServiceDoCallService,
    private WalletManager: ServiceWalletManagerService,
    private excelExport: ServiceExcelExportService,
    private ngRedux: NgRedux<IAppState>
    ) {
      //dati per il primo atterraggio
      this.ngRedux.subscribe(() => {
        let state = this.ngRedux.getState();
        if(state.lastAction === 'STORAGE_ID'){
          this.getData();
        }
      });
      this.colors = ['#02BAC578', '#a26fd158', '#20250114', '#59157914', '#02C5AF14'];
     }

  getDataChart(chartdata){
    let count = -1;
    let show = true;
    this.key = [];
    let values = [];
    let valuesx = [];
    chartdata.forEach(element => {
      for (let key in element) {
          let value = element[key];
          if(!this.key.includes(key) && key != 'date'  && key != 'year'){
              this.key.push(key);
          }
          if(key!='date' && key != "year")
          values.push(element[key]);
          if(key=="year")
          valuesx.push(element[key]);
      }
    });
    let minValue =  undefined;
    let maxValue = undefined;
    let minXValue =  undefined;
    let maxXValue = undefined;
    let unitInterval = undefined;
    if(this.key.length > 1 ){
      minValue =  Math.min(...values);
      maxValue= Math.max(...values);
      let delta = maxValue-minValue;
      unitInterval= Math.ceil(delta/5);
    }
    if(this.tresholdIndex != undefined){
      minValue =  Math.min(...values);
      maxValue= Math.max(...values);
      maxXValue= Math.max(...valuesx);
      minValue = 0.98*minValue;
      maxValue = 1.02*maxValue;
      maxXValue = 1.02* maxXValue;
    }
    if(this.key.length > 2){
      this.colors = ["#C97C5D","#02BAC578","#B3D89C"];
      if(this.key[0]=="Average")
      this.colors = ["#02BAC5","#96989c","#c7b59f"];
        //this.colors = Array(this.key.length).fill("#d7dae0"); 
    }
    if(this.key.length > 0 ){
      this.key.reverse().forEach(element => {
        count++;
        if(count >= 1)
          show = false;
          let item = {
              type: this.type,
              useGradient: false,
              showLegend: true,
              borderLine: false,
              toolTipFormatFunction:undefined,
              formatFunction:undefined,
              series: [
                  { dataField: element, displayText: element,  color: this.colors[count],lineColor:"",symbolType:undefined,labels:{visible:false}},
              ],
              valueAxis:
              {
                  displayValueAxis: show,
                  axisSize: 'auto',
                  gridLinesColor: '#e7ebef',
                  showGridLines: show,
                  minValue: minValue,
                  maxValue: maxValue,
                  unitInterval: unitInterval,
                  labels:undefined,
                  formatSettings: {
                    sufix: undefined
                  },
                  visible : show
              },
              xAxis : { 
                dataField: 'date',
                type: 'date',
                ticks:{display:show},
                visible : show,
                baseUnit: 'day',
                minValue: minXValue,
                maxValue: maxXValue,
                valuesOnTicks: true,
                gridLinesColor: '#e7ebef',
                labels: {
                  formatFunction: function (value) {
                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                    var d = new Date(value + " UTC");
                    var day = d.getUTCDate();
                    return ((day) +"-"+ monthNames[value.getMonth()]) +"-"+ value.getFullYear().toString().substr(-2);
                  }
                }
              } 
          }
          if(this.type!="line")
            item.series[0].lineColor="transparent";
          if(this.tresholdIndex != undefined){
            item.valueAxis.formatSettings.sufix="%";
            item.valueAxis.labels={visible:true,position:"inside"};
            item.xAxis.dataField="year";
            item.xAxis.type= undefined;
            item.xAxis.labels={formatFunction: function (value) { return value + " year";}}
            item.valueAxis.formatSettings.sufix="%";
            item.series[0].labels.visible=true;
            item.series[0].symbolType="circle";
            item.toolTipFormatFunction= function(value) {
              return value + "%";
            },
            item.formatFunction= function(value) {
              return value + "%";
            },
            this.label = this.key[0]
          }
          
          this.seriesGroups.push(item);
          
      });
    }
  }

  getData(){
    let seriesLabels = [];
    if(this.mode == "dialog"){
      this.getDataChart(this.data_chart);
      this.sampleData = this.data_chart;
      let timer = setTimeout(() => {
        this.myChart.update();
      }, 1);
    }
    else if(this.mode == "simulation"){
      let storage:any = this.WalletManager.GetWallet();
      let url = this.endpoint;
      let simId = storage.ptfs[this.indexWallet].simId;
      if(!this.option){
        this.option =  {"simId": simId, "tresholdIndex": this.tresholdIndex};
      }
      this.doCall.Post(url, this.option).subscribe(res => {
          if(res){
            this.sampleData = res;
            this.getDataChart(res);
          }
          let data = this.myChart.source();
          let timer = setTimeout(() => {
            this.myChart.update();
          }, 1);
      });
    }
    else{
      let storage:any = this.WalletManager.GetWallet();
      let url = this.endpoint;
      let id = this.id;
      
      let compId = this.compId;
      if(!id && storage.ptfs[this.indexWallet] != undefined){
        if(!id && storage.ptfs[this.indexWallet] != undefined){
          id = storage.ptfs[this.indexWallet].id;
        }
        if(!compId && storage.ptfs[this.indexWallet] != undefined){
          compId = storage.ptfs[this.indexWallet].compId;
        }
      }
      else{
        seriesLabels = ["Fund","Comp"];
      }
      if(!this.option){
        this.option =  {"id": id,"compId":compId,"labels":seriesLabels};
      }
      this.doCall.Post(url, this.option).subscribe(res => {
          if(res){
            this.sampleData = res;
            this.getDataChart(res);
          }
          let data = this.myChart.source();
          let timer = setTimeout(() => {
            this.myChart.update();
          }, 1);
      });
    }
  }

  ngOnInit(){
    if(this.mode == "dialog"){
      this.getData();
    }
    else{
       //dati per eventuali refresh
     let storage:any = this.WalletManager.GetWallet();
     let id = this.id;
     if(!id)
        id = storage.ptfs[this.indexWallet].id;
     if(id != ''){  
      this.getData();
     }
    }
  }


  pngExportOnClick() {
    function getExportServer() {
      return 'http://www.jquerygrid.net/export_server/export.php';
    }
    this.myChart.saveAsPNG("areaseries.png",getExportServer());
   };

   excelExportOnClick() {
    this.excelExport.exportAsExcelFile(this.sampleData,"data");
   };

   excelBtnOnClick() {
    var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840));
    let exportData = [];
   
    let allkeys = ["date"];
    for(var j=0; j < this.key.length; j++) {
      allkeys.push(this.key[j]);
    }

    for(var i=0; i < this.sampleData.length; i++) {
        let row = []
        for(var k=0; k < allkeys.length; k++) {
          if(allkeys[k] == "date")
            row.push(this.sampleData[i][allkeys[k]])
          else
            row.push(Math.ceil(this.sampleData[i][allkeys[k]]*100)/100)
        }
        if(i == 0)
          exportData.push(row);
        else if(row != exportData[i-1]){
          exportData.push(row);
        }
    }

    let table = this.makeTableHTML(exportData);
    win.document.body.innerHTML = table;
  }

  makeTableHTML(myArray) {
    var result = "<table border=1>";
    for(var i=0; i<myArray.length; i++) {
        result += "<tr>";
        for(var j=0; j<myArray[i].length; j++){
            result += "<td>"+myArray[i][j]+"</td>";
        }
        result += "</tr>";
    }
    result += "</table>";

    return result;
  }
}