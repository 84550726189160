import {Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { environment } from '../../../environments/environment';
import { IAppState } from '../../store';
import { NgRedux } from '@angular-redux/store';
import { ServiceDoCallService } from '../../services/service-do-call.service';
import { ServiceWalletManagerService } from '../../services/service-wallet-manager.service';
import { ServiceExcelExportService } from '../../services/service-excel-export.service';

export interface PeriodicElement {
  year: string,
  Jan: number,
  Feb: number,
  Mar: number,
  Apr: number,
  May: number,
  Jun: number,
  Jul: number,
  Aug: number,
  Sep: number,
  Oct: number,
  Nov: number,
  Dec: number
}

@Component({
  selector: 'app-chart-table-correlations',
  templateUrl: './chart-table-correlations.component.html',
  styleUrls: ['./chart-table-correlations.component.scss']
})
export class ChartTableCorrelationsComponent implements OnInit {
  @Input() endpoint;
  @Input() indexWallet;
  @Input() label;
  //displayedColumns: string[] = ["year", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug","Sep","Oct","Nov","Dec"];
  displayedColumns: string[];
  data : any;
  obj :any;
  dataSource: any;
  id: any;
  modelArray: any;
  counter:any;
  avg:any;
  min:any;
  max:any;
  stdev:any;
  ELEMENT_DATA2: PeriodicElement[];

  constructor(
    private doCall: ServiceDoCallService,
    private WalletManager: ServiceWalletManagerService,
    private excelExport: ServiceExcelExportService,
    private ngRedux: NgRedux<IAppState>
    ) {
      //dati per il primo atterraggio
      this.ngRedux.subscribe(() => {
        let state = this.ngRedux.getState();
        if(state.lastAction === 'STORAGE_ID'){
          this.getData();
        }
      });
      this.counter = {};
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getData(){
    let storage:any = this.WalletManager.GetWallet();
    this.id = storage.ptfs[this.indexWallet].id;
    let url = this.endpoint;
    let options =  {"id": this.id};
    this.doCall.Post(url, options).subscribe(res => {
      this.displayedColumns = res["columns"];
      this.obj = res["data"];
      this.avg = res["avg"];
      this.min = res["min"];
      this.max = res["max"];
      this.stdev = res["stdev"];
      this.ELEMENT_DATA2 = this.obj;
      this.dataSource = new MatTableDataSource( this.ELEMENT_DATA2 );
      for (var _i = 1; _i < this.displayedColumns.length; _i++) {
        this.counter[this.displayedColumns[_i]] = "#"+_i;
      }
    });
  }

  ngOnInit() {
    //dati per ogni refresh
    let storage:any = this.WalletManager.GetWallet();
    this.id = storage.ptfs[this.indexWallet].id;
    if(this.id != ''){
      this.getData();
    }
  }

  excelExportOnClick() {
    this.excelExport.exportAsExcelFile(this.obj,"data");
   };


}